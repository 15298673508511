import { appEndpoints } from '../api-endpoints';
import axios from 'axios';

import { Headers } from './config/api';

export async function getSearchResult(
  keyword: string,
  callback: (response: any) => void
) {
  const url = 'https://search.begin.watch/api/search';
  // const url = 'https://staging-elastic.begin.watch/api/search';
  const data = JSON.stringify({
    operation: 'read',
    payload: {
      term: keyword,
    },
  });

  try {
    await axios.post(url, data, { headers: Headers }).then((response) => {
      callback(response);
    });
  } catch (e) {
    callback(false);
  }
}
