import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResetPasswordModel } from '../types/local-models/reset-password.component.model';
import { BeginToastModel } from '../types/local-models/begin-toast.component.model';
import { CreateAccountOTPModel } from '../types/local-models/create-account-otp.component.model';

const resetPasswordOtpInitialVal: ResetPasswordModel = {
  account: '',
  serverResponse: {
    CodeDeliveryDetails: {
      AttributeName: '',
      DeliveryMedium: '',
      Destination: '',
    },
  },
};
const createAccountOTP: CreateAccountOTPModel = {
  accountID: '',
  // phone: '',
  password: '',
  // serverresponse: {
  //   UserConfirmed: false,
  UserSub: '',
  //   codeDeliveryDetails: {
  //     AttributeName: 'email',
  //     DeliveryMedium: 'EMAIL',
  //     Destination: '',
  //   },
  // },
};
const toast: BeginToastModel = {
  type: 'info',
  shown: false,
  text: '',
  dismissduration: 0,
};
const landingInitialState = {
  isChildLoaded: false,
  showLoader: false,
  resetPasswordOTP: resetPasswordOtpInitialVal,
  createAccountOTP: createAccountOTP,
  toast: toast,
};

const landingSlice = createSlice({
  name: 'LandingSlice',
  initialState: landingInitialState,
  reducers: {
    childLoading(state) {
      state.isChildLoaded = false;
    },
    childLoaded(state) {
      state.isChildLoaded = true;
    },
    hideLoader(state) {
      state.showLoader = false;
    },
    showLoader(state) {
      state.showLoader = true;
    },
    resetPasswordOTP(state, action: PayloadAction<ResetPasswordModel>) {
      state.resetPasswordOTP = action.payload;
    },
    createAccountOTP(state, action: PayloadAction<CreateAccountOTPModel>) {
      state.createAccountOTP = action.payload;
    },
    hideToast(state) {
      state.toast = toast;
    },
    showToast(state, action: PayloadAction<BeginToastModel>) {
      state.toast = action.payload;
    },
  },
});

export const landingActions = landingSlice.actions;

export default landingSlice;
