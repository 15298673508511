import React, { useEffect, useState } from 'react';
import style from '../partials/partials.module.css';
import {
  SubscriptionsAPI,
  customerUnsubscription,
} from '../../../services/subscriptions';
import BeginButton from '../../../components/begin-button/begin-button';
import Modal from './subscriptions-modal';
import { useNavigate } from 'react-router-dom';
import { FaCheckSquare } from 'react-icons/fa';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import menu from '../../../assets/images/icons/menu.png';
import discountImg from '../../../assets/images/subscriptions/discount.png';
import { WebEngageEvents, WebEngageEventDescriptions } from '../../../webEngageEvents';
import { useParams } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
mixpanel.init('9913d3bf0504bcc0c3ac83182867f483', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});

const MySubscriptions: React.FC = () => {


  const [allSubscriptions, setAllSubscriptions] = useState<any[]>([]);
  const [mySubscriptions, setMySubscriptions] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<React.ReactNode>(null);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [modalActions, setModalActions] = useState<
    { text: string; onClick: (subscription: any, text?: string) => void }[]
  >([]);
  const [showTextArea, setShowTextArea] = useState<boolean>(false);
  const navigate = useNavigate();
  const [subscriptionBillingIndices, setSubscriptionBillingIndices] = useState<{
    [key: string]: number;
  }>({});



  const { status = '' } = useParams();

  useEffect(() => {

    if (status === 'success') {
      ReactGA.event({
        category: 'Settings-Subscriptions',
        action: 'SubscriptionsAndPayment_success ',
        nonInteraction: false,
      });
      ReactPixel.track('SubscriptionsAndPayment_success');
      window.webengage.track('plan_subscribed',
        {
          'event_source': 'Web',
        })
      mixpanel.track('plan_subscribed',
        {
          'event_source': 'Web',
        })
      // Your logic here to trigger the event, e.g., sending to WebEngage


      //Elastic Api to refresh user data..

    }
    else {
      if (status === 'error') {
        ReactGA.event({
          category: 'Settings-Subscriptions',
          action: 'Payment_failure',
          nonInteraction: false,
        });
        ReactPixel.track('Payment_failure');
        window.webengage.track('payment_failed',
          {
            'event_source': 'Web',
          })
        mixpanel.track('payment_failed',
          {
            'event_source': 'Web',
          })

      }

    }
    ReactGA.event({
      category: 'Settings-Subscriptions',
      action: 'SS_View',
      nonInteraction: false,
    });
    ReactPixel.track('SS_View');
  }, []);

  const getAllSubscriptions = () => {
    SubscriptionsAPI.getAllCustomerSubscriptions().then((response) => {
      if (response) {
        setMySubscriptions(response);
      }

      SubscriptionsAPI.getAll(null).then((responseAllSubs: any[]) => {
        const temp: any[] = [];
        responseAllSubs.forEach((element) => {
          if (
            typeof response !== 'undefined' &&
            !response.find(
              (sub: any) => sub?.subscriptionID === element.subscriptionID
            )
          ) {
            temp.push(element);
          }
        });
        setAllSubscriptions(temp);
      });
    });
  };

  useEffect(() => {
    getAllSubscriptions();
  }, []);

  const closeModal = () => {
    getAllSubscriptions();
    setShowModal(false);
  };

  const SuccessModal = () => {
    setModalTitle('Success');
    setModalContent(
      <p>You have successfully unsubscribed from the Local package.</p>
    );
    setModalActions([
      {
        text: 'Okay',
        onClick: closeModal,
      },
    ]);
    setShowModal(true);
    setShowTextArea(false);
  };

  const successUnsubscribe = (subscription: any, text?: string) => {
    if (subscription.subscriptionID) {
      localStorage.setItem(
        'selectedSubscriptionID',
        subscription.subscriptionID
      );
      localStorage.setItem('unSubscriptionReason', text || '');

      customerUnsubscription((response) => {
        SuccessModal();
      });
    } else {
      console.error('No subscription selected');
    }
  };

  const showReasonModal = (subscription: any) => {
    setModalContent(
      <p>
        We&apos;re sorry to see you leave. Can you tell us why you&apos;re
        unsubscribing?
      </p>
    );
    setModalActions([
      {
        text: 'Next',
        onClick: (text?: string) => successUnsubscribe(subscription, text),
      },
      {
        text: 'Cancel',
        onClick: closeModal,
      },
    ]);
    setShowModal(true);
    setShowTextArea(true);
  };

  const unsubscribe = (subscription: any) => {
    showReasonModal(subscription);
  };

  const showConfirmationModal = (subscription: any) => {
    setModalTitle('Are you sure');
    setModalContent(<p>That you would like to unsubscribe?</p>);
    setModalActions([
      {
        text: 'Yes',
        onClick: () => unsubscribe(subscription),
      },
      {
        text: 'Cancel',
        onClick: closeModal,
      },
    ]);
    setShowModal(true);
    setShowTextArea(false);
  };

  const confirmation = (subscription: any) => {
    showConfirmationModal(subscription);
  };

  const subscribe = (subscription: any) => {
    ReactGA.event({
      category: 'Settings-Subscriptions',
      action: 'SS_PackageSelect_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('SS_PackageSelect_Initiate');
    localStorage.setItem(
      'subscriptionsListLocal',
      JSON.stringify(allSubscriptions)
    );
    localStorage.setItem(
      'selectedSubscriptions',
      JSON.stringify(subscription.subscriptionTitle)
    );
    localStorage.setItem(
      'subscriptionDurations',
      JSON.stringify(subscription.subscriptionPricing)
    );
    localStorage.setItem('selectedSubscriptionID', subscription.subscriptionID);

    const billingIndex =
      subscriptionBillingIndices[subscription.subscriptionID] || 0;

    localStorage.setItem(
      'selectedBillingCycle',
      subscription.subscriptionPricing[billingIndex].type
    );

    subscription.selectedBillingCycle =
      subscription.subscriptionPricing[billingIndex].billing;
    subscription.price = subscription.subscriptionPricing[billingIndex].price;
    subscription.trial = subscription.subscriptionPricing[billingIndex].trial;

    const selectedSubscription = {
      selectedSubscriptions: subscription,
      selectedSubscriptionID: subscription.subscriptionID,
      selectedPrice: subscription.price,
      selectedTrialDays: subscription.trial,
      selectedDiscount: subscription.discount,
      selectedBillingCycle: subscription.selectedBillingCycle,
      originalPrice: subscription.originalPrice,
    };

    const subscriptionList = {
      subscriptionPaymentMethods: subscription.subscriptionPaymentMethods,
    };

    navigate('/new-payment', {
      state: {
        selectedSubscription: selectedSubscription,
        subscriptionList: subscriptionList,
      },
    });
  };

  const handleUnsubscribe = (subscription: any) => {
    setModalTitle(subscription.subscriptionTitle);
    setModalContent('');
    setModalActions([
      {
        text: 'Unsubscribe',
        onClick: () => confirmation(subscription),
      },
      {
        text: 'Close',
        onClick: closeModal,
      },
    ]);
    setShowModal(true);
    setShowTextArea(false);
  };

  const handlePricingChange = (subscription: any, idx: number) => {
    setSubscriptionBillingIndices((prevState) => ({
      ...prevState,
      [subscription.subscriptionID]: idx,
    }));
  };

  const handleSubscription = (subx: any) => {
    setModalTitle(subx.subscriptionTitle);
    // setModalContent('Some text from api, whcih will be displayed later.');
    setModalActions([
      {
        text: 'Subscribe',
        onClick: () => subscribe(subx),
      },
      {
        text: 'Close',
        onClick: closeModal
      }
    ]);
    setShowModal(true);
    setShowTextArea(false);
  }

  return (
    <section
      id="my-subscription"
      className={`${style.section} ${style.subscriptionSection}`}
    >
      {mySubscriptions && mySubscriptions.length > 0 && (
        <div>
          <h1>My Subscriptions</h1>

          <div className={style.subscriptionsContainer}>
            {mySubscriptions.map(
              (subscription) =>
                subscription && (
                  <div
                    className={style.subscriptionCard}
                    key={`my-sub-${subscription.subscriptionID}`}
                  >
                    <h2>{subscription?.subscriptionTitle}</h2>
                    <div className={style.price}>
                      {subscription?.subscriptionPricing[0]?.discount === 0 ? (
                        `${subscription?.subscriptionPricing[0]?.price} PKR`
                      ) : (
                        <>
                          <span className={style.originalPrice}>
                            {
                              subscription?.subscriptionPricing[0]
                                ?.originalPrice
                            }{' '}
                            PKR
                          </span>
                          <span className={style.discountedPrice}>
                            {subscription?.subscriptionPricing[0]?.price} PKR
                          </span>
                        </>
                      )}
                    </div>
                    <div
                      className={style.subscribed}
                      onClick={() => handleUnsubscribe(subscription)}
                    >
                      <span>Unsubscribe</span>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}

      {allSubscriptions.length > 0 && (
        <div>
          <h1>All Subscriptions</h1>

          <div
            className={`${style.subscriptionsContainer} ${style.newSubscriptions}`}
          >
            {allSubscriptions.map((subscription) => {
              const billingIndex =
                subscriptionBillingIndices[subscription.subscriptionID] || 0;
              const currentPricing =
                subscription.subscriptionPricing[billingIndex];
              const isDiscounted = currentPricing.discount !== 0;

              return (
                <div
                  className={style.subscriptionCard}
                  key={`sub-${subscription?.subscriptionID}`}
                >
                  {isDiscounted && (
                    <div
                      style={{
                        position: 'absolute',
                        marginLeft: '319px',
                        marginTop: '-210px',
                      }}
                    >
                      <img
                        style={{ height: '56px', width: '56px' }}
                        src={discountImg}
                        alt="Discount"
                      />
                      <p
                        style={{
                          position: 'relative',
                          marginTop: '-50px',
                          marginLeft: '5px',
                          fontSize: '17px',
                          fontWeight: 'bold',
                        }}
                      >
                        {currentPricing.discount}%
                      </p>
                      <p
                        style={{
                          position: 'relative',
                          marginTop: '-9px',
                          marginLeft: '3px',
                          fontSize: '12px',
                        }}
                      >
                        OFF
                      </p>
                    </div>
                  )}

                  <h2>{subscription?.subscriptionTitle}</h2>
                  <div className={style.innerContainer}>
                    <div className={style.price}>
                      {subscription?.subscriptionPricing.length === 1 && (
                        <>
                          {currentPricing.discount === 0 ? (
                            <>
                              <br />
                              {currentPricing.price} PKR
                            </>
                          ) : (
                            <>
                              <span className={style.originalPrice}>
                                {currentPricing.originalPrice} PKR{'   '}
                              </span>
                              {currentPricing.price} PKR
                            </>
                          )}
                        </>
                      )}

                      {subscription?.subscriptionPricing.length > 1 && (
                        <>
                          {currentPricing.discount === 0 ? (
                            <>{currentPricing.price} PKR</>
                          ) : (
                            <>
                              <span className={style.originalPrice}>
                                {currentPricing.originalPrice} PKR{'  '}
                              </span>
                              {currentPricing.price} PKR
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <div className={style.pricingContainer}>
                      {subscription?.subscriptionPricing.length > 1 &&
                        subscription?.subscriptionPricing.map(
                          (pricing: any, idx: number) => (
                            <button
                              key={idx}
                              className={`${style.pricingButton} ${idx === billingIndex ? style.active : ''
                                }`}
                              onClick={() =>
                                handlePricingChange(subscription, idx)
                              }
                            >
                              {pricing.title}
                            </button>
                          )
                        )}
                    </div>
                  </div>
                  <div className={style.subscribeBtn}>
                    <BeginButton
                      datalabel="Subscribe"
                      className="primary"
                      preset="button"
                      type="button"
                      onClick={() => {
                        handleSubscription(subscription);
                      }}
                    />
                  </div>
                  <div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      {showModal && (
        <Modal
          title={modalTitle}
          content={modalContent}
          actions={modalActions}
          onClose={closeModal}
          showTextArea={showTextArea}
        />
      )}
    </section>
  );
};

export default MySubscriptions;
