import React, { FC, useEffect, useState } from 'react';

import styles from './Home.module.css';
import logo from '../../assets/images/logo.png';
import BeginButton from '../../components/begin-button/begin-button';
import ReactGA from 'react-ga4';
import { Link, useNavigate } from 'react-router-dom';
import {
  HomeComponentsEnum,
  HomeProps,
} from '../../types/local-models/home.view';
import LandingSections from '../../components/LandingModules/LandingSections';
import PrivacyPolicy from '../../components/LandingModules/PrivacyPolicy';
import TermsAndConditions from '../../components/LandingModules/TermsAndConditions';
import ReactPixel from 'react-facebook-pixel';
import { motion } from 'framer-motion';
import mixpanel from 'mixpanel-browser';

mixpanel.init('9913d3bf0504bcc0c3ac83182867f483', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  });
const Home = (prop: HomeProps) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const logGA = () => {
    ReactGA.event({
      category: 'Landing Page',
      label: 'Create Account Initiate',
      action: 'LP_GoTo_CreateAccount_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('LP_GoTo_CreateAccount_Initiate');
    window.webengage.track('login_button_clicked', { 'event_source': 'Web' });
    mixpanel.track('login_button_clicked', {'event_source': 'Web'})

  };

  const logGAPrivacyPolicy = () => {
    ReactGA.event({
      category: 'Landing Page',
      action: 'LP_GoTo_PrivacyPolicy_Initiate',
      label: 'PrivacyPolicy',
      nonInteraction: false,
    });
    ReactPixel.track('LP_GoTo_PrivacyPolicy_Initiate');
  };

  const logGATermsnadCon = () => {
    ReactGA.event({
      category: 'Landing Page',
      action: 'LP_GoTo_T&C_Initiate',
      label: 'TermsAndConditions',
      nonInteraction: false,
    });
    ReactPixel.track('LP_GoTo_T&C_Initiate');
  };

  const containerVariants = {
    offscreen: {
      y: -50,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.1,
        duration: 1.2,
        y: { duration: 1 },
      },
    },
  };

  const navigate = useNavigate();
  const getSection = () => {
    switch (prop.component) {
      case HomeComponentsEnum.home:
        return <LandingSections />;
      case HomeComponentsEnum.privacy:
        return <PrivacyPolicy />;
      case HomeComponentsEnum.terms:
        return <TermsAndConditions />;
      default:
        return <LandingSections />;
    }
  };

  const toLanding = () => {
    if (prop.isMobile) return;
    navigate('/');
  };

  return (
    <div id="home" className={styles.Home}>
      <div id="home-container" className={styles.container}>
        <motion.header
          className={styles.head}
          layout
          variants={containerVariants}
          initial="offscreen"
          whileInView="onscreen"
          animate={'show'}
        >
          <img className={styles.logo} src={logo} onClick={toLanding} />

          {!prop.isMobile && (
            <div className={styles.signIn}>
              {prop.component != HomeComponentsEnum.home && (
                <BeginButton
                  preset="button"
                  type="button"
                  className="primary"
                  to="/"
                  iconOnly={true}
                  dataiconstart={'icon-left'}
                  squared={true}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              )}

              <BeginButton
                preset="link"
                type="button"
                className="primary"
                to="/account"
                onClick={logGA}
                datalabel={
                  prop.component == HomeComponentsEnum.home && !isMobile
                    ? 'Login'
                    : undefined
                }
                dataiconstart={'icon-locked'}
                iconOnly={prop.component != HomeComponentsEnum.home || isMobile}
                squared={true}
              />
            </div>
          )}
        </motion.header>

        {getSection()}

        {!prop.isMobile && (
          <footer className={styles.footer}>
            <Link to="/privacy-policy" onClick={logGAPrivacyPolicy}>
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" onClick={logGATermsnadCon}>
              Terms &amp; Conditions
            </Link>
          </footer>
        )}
      </div>
    </div>
  );
};

export default Home;
