import React, { useEffect } from 'react';
import { refreshCognitoToken } from '../../services/Cognito';

const RefreshToken: React.FC = () => {
  useEffect(() => {
    const refreshToken = async () => {
      await refreshCognitoToken((err, result) => {
        if (err || result === null) {
          window.location.href = '/account/login';
        } else {
          window.location.href =
            localStorage.getItem('lastLocation') || '/sports';
        }
      });
    };

    refreshToken();
    return () => {
      // Add any cleanup code here
      // This code will run when the component unmounts or when the dependencies change
    };
  }, []); // Add any dependencies inside the array if needed

  return <div>{/* Add your JSX code here */}</div>;
};

export default RefreshToken;
