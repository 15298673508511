export interface HomeProps {
  component: HomeComponentsEnum;
  isMobile?: boolean;
}

export enum HomeComponentsEnum {
  'home',
  'privacy',
  'terms',
}
