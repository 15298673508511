import React, { useEffect } from 'react';
import Loading from '../../../components/Loading/Loading';
import { useNavigate } from 'react-router-dom';

const SubscriptionStatus: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/live/available-subscriptions');
  }, []);
  return (
    <div>
      <Loading show={true} />
    </div>
  );
};

export default SubscriptionStatus;
