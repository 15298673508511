import React from 'react';

import style from './shimmer.module.css';

interface ShimmerProps {
  variant: 'poster' | 'banner' | 'search' | 'card';
  visible: boolean;
}

const Shimmer: React.FC<ShimmerProps> = ({ variant, visible }) => {
  const [shimmerCards, setShimmerCards] = React.useState<number[]>([]);

  React.useEffect(() => {
    if (variant === 'card') {
      setShimmerCards([0]);
    } else {
      const numberOfCards = (window.innerWidth - 112) / 224;

      const cards = Array.from(Array(Math.floor(numberOfCards)).keys());
      setShimmerCards(cards);
    }
  }, [variant]);

  return (
    <div className={`${style.shimmerContainer} ${visible ? style.active : ''}`}>
      {shimmerCards.map((i) => (
        <div
          key={i}
          className={style.shimmer}
        ></div>
      ))}
      blah
    </div>
  );
};

export default Shimmer;
