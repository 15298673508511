import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './MobileLogin.module.css';
import { getUserByIDToken } from '../../services/Cognito';
import { useNavigate } from 'react-router-dom';

enum Status {
  LOADING = 0,
  SUCCESS = 1,
  ERROR = -1,
}

declare global {
    interface Window {
        authToken?: string;
    }
}

const MobileLogin = () => {
  const params = useParams();
  const [error, setError] = useState('');
  const [status, setStatus] = useState(Status.LOADING);
  const navigate = useNavigate();

  useEffect(() => {
    const idToken = params.idtoken || '';
    const type = params.type || '';
    const id = params.id || '';
    const seasonId = params.seasonid || '';
    // console.log(idToken);
    localStorage.setItem('aToken', idToken);
    if (idToken !== '') {
      getUserByIDToken(idToken).then((response) => {
        if (typeof response === 'string') {
          setError(response);
          setStatus(Status.ERROR);
        } else {
          console.log(response.email);
          const accountID: string = response.email?.toString() || '';
          localStorage.setItem('accountID', accountID);
          setStatus(Status.SUCCESS);
          if(type == 'episode') {
            navigate(`/new-available-subscriptions/${type}/${seasonId}/${id}`);
          } else {
          navigate(`/new-available-subscriptions/${type}/${id}`);
          }
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
      });
    }
  }, [params]);

  return (
    <div className={styles.container}>
      {status === Status.LOADING && <div>Loading...</div>}
      {status === Status.SUCCESS && (
        <div>
          Success, Continuing with the Subscription Flow...
          <a href="beginwatchapp://">Back to the app...</a>
        </div>
      )}
      {status === Status.ERROR && <div>Error: {error}</div>}
    </div>
  );
};

export default MobileLogin;
