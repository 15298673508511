import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch } from 'react-redux/es/exports';
import style from '../auth-child.module.css';
import modStyle from './PaymentMethods.module.css';

import ProgressDots from '../../../components/progress-dots/progress-dots';
import { useNavigate } from 'react-router-dom';

import { PaymentMethodsList } from './paymentMethodsList';

import { FaChevronLeft } from 'react-icons/fa';

const PaymentMethods: React.FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 1500);
  }, []);

  return (
    <div className={style.authContainer}>
      <div className={style.form}>
        <div className={style.authContent}>
          <h1 className="title">
            <FaChevronLeft
              className={style.backBtn}
              onClick={() => navigate(-1)}
            />
            Payment Methods
          </h1>

          <div className={style.group}>
            <ProgressDots
              dots={3}
              activeIndex={2}
            />

            <h1>Please Choose Your Payment Method</h1>

            <div className={modStyle.paymentMethods}>
              {PaymentMethodsList.map((method, index) => (
                <div
                  key={index}
                  className={modStyle.paymentMethod}
                  onClick={() => {
                    localStorage.setItem('selectedPaymentMethod', method.value);
                    navigate('/account/payment');
                  }}
                >
                  <div
                    className={modStyle.methodIcon}
                    style={{
                      backgroundImage: `url(/payment-methods/${method.icon})`,
                    }}
                  ></div>
                  <div className={modStyle.methodName}>{method.label}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
