import React from 'react';
import styles from './Loading.module.css';
import { LoadingModel } from '../../types/local-models/loading.component.model';

const Loading = (props: LoadingModel) => {
  return (
    <div className={ styles.loading + ' ' + (props.show ? styles.show : '') }>
      <div className={ styles.loadingAnimation }></div>
    </div>
  );
};

export default Loading;