import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import styles from './FAQ.module.css';
interface ID {
  id: string;
}
function FAQ() {
  const [selectedTab, setSelectedTab] = useState<
    'general' | 'account' | 'application' | 'about'
  >('general');

  const [openedAccordion, setOpenedAccordion] = useState<ID[] | null>(null);

  const handleTabChange = (
    tab: 'general' | 'account' | 'application' | 'about'
  ) => {
    setSelectedTab(tab);
  };

  const handleAccordionChange = (accordionItemIds: string[]) => {
    const accordionItems: ID[] = accordionItemIds.map((id) => ({ id }));
    if (accordionItems && accordionItems.length > 0) {
      setOpenedAccordion(accordionItems);
    } else {
      setOpenedAccordion(null);
    }
  };

  const isAccordionItemOpen = (id: string) => {
    return openedAccordion && openedAccordion.some((item) => item.id === id);
  };

  return (
    <>
      <div className={styles.tabs}>
        <label
          className={
            selectedTab === 'general' ? styles.activeTab : styles.inactive
          }
          onClick={() => handleTabChange('general')}
        >
          General
        </label>
        <label
          className={
            selectedTab === 'account' ? styles.activeTab : styles.inactive
          }
          onClick={() => handleTabChange('account')}
        >
          Account
        </label>
        <label
          className={
            selectedTab === 'application' ? styles.activeTab : styles.inactive
          }
          onClick={() => handleTabChange('application')}
        >
          Application
        </label>
        <label
          className={
            selectedTab === 'about' ? styles.activeTab : styles.inactive
          }
          onClick={() => handleTabChange('about')}
        >
          About Begin
        </label>
      </div>

      {selectedTab === 'general' && (
        <Accordion
          className={styles.accordion_custom}
          preExpanded={
            openedAccordion ? openedAccordion.map((item) => item.id) : []
          }
          onChange={handleAccordionChange}
        >
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="general-1"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('general-1') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['general-1'])}
              >
                What is Begin?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Begin is an Over-The-Top streaming platform that offers a
                diverse range of on-demand video content, including movies,
                series, and exclusive originals. It allows you to stream content
                anytime, anywhere, on-the-go.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="general-2"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('general-2') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['general-2'])}
              >
                Is Begin free?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Begin offer ad-free premium content. There are various
                subscription packages tailored to our customer’s content
                appetite.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="general-3"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('general-3') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['general-3'])}
              >
                What can I watch on Begin?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Begin boasts a large library from the top studios along with
                live sporting events such as Cricket, Golf, Tennis and Football
                to provide our customers with a one-stop solution for their
                entertainment needs.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="general-4"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('general-4') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['general-4'])}
              >
                Is Begin child friendly?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Begin provides an option to make a separate Kids profile for
                children under 7. To set maturity ratings, click on Manage
                profiles and select the profile you want to modify.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="general-5"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('general-5') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['general-5'])}
              >
                How can I cancel my subscription?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                On the Settings page, go to My Payments, click on Subscriptions
                and Payment to view your Payments, Subscriptions and Card
                Details. Click on My Payments and you will see an “Unsubscribe”
                button at the bottom.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="general-6"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('general-6') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['general-6'])}
              >
                Where can I watch Begin?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                You can stream on Begin from your PC, Android and IOS devices.
                You can download the Begin application from the IOS App store or
                Google Playstore on your mobile devices or Smart TVs.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}

      {selectedTab === 'account' && (
        <Accordion
          preExpanded={
            openedAccordion ? openedAccordion.map((item) => item.id) : []
          }
          onChange={handleAccordionChange}
        >
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-1"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-1') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-1'])}
              >
                How can I manage/change your plan?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                In My Account, click on Subscriptions and Payment to view your
                Payments, Subscriptions and Card Details. Click on My
                Subscriptions to view your current plan. Here you can
                unsubscribe/subscribe to packages offered by Begin.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-2"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-2') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-2'])}
              >
                How can I cancel my subscription?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                On the Settings page, go to My Payments, click on Subscriptions
                and Payment to view your Payments, Subscriptions and Card
                Details. Click on My Payments and you will see an “Unsubscribe”
                button on the bottom.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-3"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-3') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-3'])}
              >
                How can I change or reset my password?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                I forgot my password.Click forgot password on login page in app.
                Enter your email or mobile number you used to signed up. You
                will receive an OTP on your details which you can enter on the
                screen to reset your password.
              </p>
            </AccordionItemPanel>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                I want to change my password.Go to My Account. Click on the pen
                on the right of My Password to edit your password.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-4"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-4') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-4'])}
              >
                How can I update my account information?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Go to My Account. Click on the pen on the right to edit your
                details.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-5"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-5') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-5'])}
              >
                How can I pay for my subscriptions?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                After selecting your subscription package and whether you opt
                for the annual or monthly billing option, you will be shown the
                billing amount. After clicking next, you will be redirected to
                the Payments page. Payment Options will be shown. We are
                currently accepting payment via Debit/Credit Cards, EasyPaisa
                and JazzCash. You can select the option which suits you best and
                enter your information to pay for your plan.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-6"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-6') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-6'])}
              >
                What is my billing date?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                To view your billing date, go to My Payments on the setting page
                to see your upcoming payment and payment history.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-7"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-7') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-7'])}
              >
                How can I remove my payment details?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                On the Settings page, go to My Payments, click on Subscriptions
                and Payment to view your Payments, Subscriptions and Card
                Details. Click on Card details to view your payment methods.
                Here you can add/remove and set as default your preferred
                details.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-8"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-8') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-8'])}
              >
                How many devices can I log in my account?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>Unlimited devices can log into the account</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="account-9"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('account-9') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['account-9'])}
              >
                How many devices can stream on Begin at one time?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>4 devices can stream on Begin at one time</p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}

      {selectedTab === 'application' && (
        <Accordion
          preExpanded={
            openedAccordion ? openedAccordion.map((item) => item.id) : []
          }
          onChange={handleAccordionChange}
        >
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="application-1"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('application-1')
                    ? styles.activeQuestion
                    : ''
                }`}
                onClick={() => handleAccordionChange(['application-1'])}
              >
                How can I create/modify my profiles?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                On the settings menu, your profiles would be listed on the top.
                To add/create a profile, click on the “+” to add a new profile.
              </p>
            </AccordionItemPanel>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                To change already created profiles, click on Manage profiles and
                select the profile you want to modify.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="application-2"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('application-2')
                    ? styles.activeQuestion
                    : ''
                }`}
                onClick={() => handleAccordionChange(['application-2'])}
              >
                How can I set maturity ratings on a profile?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                To set maturity ratings, click on Manage profiles and select the
                profile you want to modify. You have the option to make the
                profile child friendly or to hide mature content.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="application-3"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('application-3')
                    ? styles.activeQuestion
                    : ''
                }`}
                onClick={() => handleAccordionChange(['application-3'])}
              >
                How can I download content on Begin?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>Begin does not allow users to download content</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="application-4"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('application-4')
                    ? styles.activeQuestion
                    : ''
                }`}
                onClick={() => handleAccordionChange(['application-4'])}
              >
                How can I request specific titles?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>This feature will be available soon</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="application-5"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('application-5')
                    ? styles.activeQuestion
                    : ''
                }`}
                onClick={() => handleAccordionChange(['application-5'])}
              >
                How to search on Begin?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Click on the search bar at the top and type in your search query
                example “Tom Cruise movies” or “adventure”. You can search by
                name, cast & crew, and genre.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}

      {selectedTab === 'about' && (
        <Accordion
          preExpanded={
            openedAccordion ? openedAccordion.map((item) => item.id) : []
          }
          onChange={handleAccordionChange}
        >
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="about-1"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('about-1') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['about-1'])}
              >
                What is Begin?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Begin stands out as an Over-The-Top streaming platform
                presenting a diverse array of on-demand video content,
                encompassing movies, series, and exclusive originals. This
                platform provides the flexibility to stream content at your
                convenience, anytime and anywhere, ensuring an on-the-go viewing
                experience .
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="about-2"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('about-2') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['about-2'])}
              >
                How is Begin different?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              <p>
                Begin distinguishes itself by providing premium, ad-free content
                and an extensive library, offering a wealth of entertainment
                options .
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem
            className={styles.accordion_item_custom}
            uuid="about-3"
          >
            <AccordionItemHeading>
              <AccordionItemButton
                className={`${styles.accordion__button} ${
                  isAccordionItemOpen('about-3') ? styles.activeQuestion : ''
                }`}
                onClick={() => handleAccordionChange(['about-3'])}
              >
                Is Begin available on multiple platforms?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              You can stream on Begin from your PC, Android and IOS devices. You
              can download the Begin application from the IOS App store or
              Google Playstore.
            </AccordionItemPanel>
            <AccordionItemPanel className={styles.accordion__panel_custom}>
              Begin will soon launch Android and Apple Television applications.
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
}
export default FAQ;
