import React, { useEffect, useState } from 'react';
import { GetDeviceList } from '../../../services/Cognito';
import ReactGA from 'react-ga4';
import styles from '../SettingsModules.module.css';
import ReactPixel from 'react-facebook-pixel';

interface Device {
  DeviceCreateDate: string;
  DeviceLastAuthenticatedDate: string;
  deviceName: string;
  deviceIP: string;
}

const DevicesSettings: React.FC = () => {
  const [deviceList, setDeviceList] = useState<Device[]>([]);

  const dateTime = (jsTime: number) => {
    const unixTime = Number(jsTime) * 1000;
    const dateObject = new Date(unixTime);

    const humanDateFormat = dateObject.toLocaleString('en-GB');

    return humanDateFormat;
  };

  useEffect(() => {
    ReactGA.event({
      category: 'Settings-Devices',
      action: 'SD_View',
      nonInteraction: false,
    });
    ReactPixel.track('SD_View');
  });

  useEffect(() => {
    GetDeviceList((err, result) => {
      if (err) {
        console.log(err);
      } else {
        const devices: Device[] = [];
        result.Devices.forEach((device: any, index: any) => {
          console.log('device', device);
          devices.push({
            DeviceCreateDate: dateTime(device.DeviceCreateDate),
            DeviceLastAuthenticatedDate: dateTime(
              device.DeviceLastAuthenticatedDate
            ),
            deviceName: device.DeviceAttributes[1].Value,
            deviceIP: device.DeviceAttributes[2].Value,
          });

          console.log('setting device list', devices, ' > index:', index);
          if (index <= result.Devices.length - 1) {
            setDeviceList(devices);
          }
        });
      }
    });
  }, []);

  return (
    <div>
      {Array.isArray(deviceList) &&
        deviceList.length > 0 &&
        deviceList.map((device: any, index: number) => {
          return (
            <div key={index} className={styles.deviceEntry}>
              <h3>{device.deviceName}</h3>
              <p>
                <b>IP Address:</b>
                <br />
                {device.deviceIP}
              </p>
              <div className={styles.deviceDates}>
                <p>
                  <b>Created On:</b>
                  <br />
                  {device.DeviceCreateDate}
                </p>
                <p>
                  <b>Last Used:</b>
                  <br />
                  {device.DeviceLastAuthenticatedDate}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default DevicesSettings;
