import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import style from './begin-input.module.css';
import { BeginInputModel } from '../../types/local-models/begin-input.component.model';

function BeginInput(props: BeginInputModel) {
  const { type } = props;
  const [showPassword, setShowPassword] = useState(false);

  const isTypeSearch = type === 'search';
  const isTypeTel = type === 'tel';

  return (
    <>
      <div className={style.formFieldContainer}>
        {props.type === 'tel' && (
          <div className={style.flagContainer}>
            <div className={style.flag} />
            +92
          </div>
        )}
        {isTypeSearch && <SearchIcon className={style.search} />}
        <input
          autoFocus={isTypeSearch}
          className={
            isTypeSearch
              ? style.searchStyle
              : `${style.baseStyle} ${props.className} ${
                  isTypeTel ? style.tel : ''
                }`
          }
          type={
            props.type === 'password' && !showPassword ? 'password' : 'text'
          }
          placeholder={props.placeholder}
          pattern={props.pattern}
          name={props.name}
          id={props.id}
          value={props.value}
          required={props.required}
          onChange={props.onChange}
          onKeyUp={props.onKeyUp}
          onPaste={props.onPaste}
          onClick={props.onClick}
          onBlur={props.onBlur}
          readOnly={props.readonly}
          autoComplete={props.autoComplete}
          role={props.role}
        />
        {props.type === 'password' && (
          <div
            className={style.showPasswordIcon}
            onClick={() => setShowPassword((prev) => !prev)}
          >
            <i className={showPassword ? 'icon-hide' : 'icon-show'} />
          </div>
        )}
        {isTypeSearch && props.value !== '' && (
          <CloseIcon
            className={style.clearSearch}
            onClick={() => {
              if (typeof props !== 'undefined' && props.onChange) {
                props.onChange({ target: { value: '' } } as any);
              }
            }}
          />
        )}
      </div>
      {props.error_message && (
        <div className={style.errorMessage}>{props.error_message}</div>
      )}
    </>
  );
}

export default BeginInput;
