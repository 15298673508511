import { ScreenComponents } from '../../types/local-models/begin-views';

interface Payload {
  screen: 'home' | 'view all';
  genre?: string;
  select?: [];
  filter?: object;
  operation?: string;
  seriesType?: string;
  profileID?: string;
}

export const getPayLoad = ({
  operation = 'list',
  screen = 'view all',
  select = [],
  filter = [],
  seriesType,
  genre,
  profileID,
}: Payload) => {
  return {
    operation,
    payload: {
      genre,
      screen,
      select,
      filter,
      seriesType,
      profileID,
    },
  };
};

export const getCachedPayload = ({
  operation = 'read',
  term = 'movies',
  id
}: any) => {
  return {
    operation,
    payload: {
      term
    }
  }
}
