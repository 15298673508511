export async function LoginFunc() {
  const { Login } = await import('../views/Auth/Login/Login');
  return { Component: Login };
}

export async function SignupFunc() {
  const { CreateAccount } = await import(
    '../views/Auth/CreateAccount/CreateAccount'
  );
  return { Component: CreateAccount };
}
