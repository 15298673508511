import React, { useEffect, useState } from 'react';
import style from '../auth-child.module.css';
import avatarSelectionStyle from './Profile.module.css';
import BeginButton from '../../../components/begin-button/begin-button';
import { FaExclamationTriangle } from 'react-icons/fa';

import { useParams, useNavigate } from 'react-router-dom';
import { ProfileAPI } from '../../../services/profile';
import Loading from '../../../components/Loading/Loading';
import mixpanel from 'mixpanel-browser';
mixpanel.init('9913d3bf0504bcc0c3ac83182867f483', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  });
interface DeleteProfile {
  setShouldUpdate: any;
}

const DeleteProfile: React.FC<DeleteProfile> = ({
  setShouldUpdate,
}: DeleteProfile) => {
  const navigate = useNavigate();

  const [profile, setProfile] = useState<any>({});
  const [profileLoading, setProfileLoading] = useState(false);
  const [error, setError] = useState('');

  const params = useParams();

  useEffect(() => {
    if (params.profileID) {
      setProfileLoading(true);
      ProfileAPI.getProfile(params.profileID).then((response) => {
        setProfileLoading(false);
        if (response.length > 0) {
          setProfile(response[0]);
        } else {
          setError('The specified profile could not be found');
        }
      });
    }
  }, []);

  return (
    <div className={style.authContainer}>
      <div
        className={`${style.authContent} ${avatarSelectionStyle.profileDeleteContainer}`}
      >
        {error === '' && (
          <>
            <Loading show={profileLoading} />
            <h1 className="title">Delete Profile</h1>
            <div className="preHeading">Please confirm profile deletion</div>

            <div className={avatarSelectionStyle.profileList}>
              <div className={avatarSelectionStyle.profileListItem}>
                <img
                  src={profile.acctProfileAvatar}
                  alt=""
                  className={avatarSelectionStyle.profileImage}
                />
                <p>{profile.acctProfileName}</p>
              </div>
            </div>

            <BeginButton
              datalabel="Delete Profile"
              squared={true}
              type="button"
              to=""
              className="primary"
              onClick={() => {
                ProfileAPI.deleteProfile(profile.acctProfileID).then(
                  (response) => {
                    setShouldUpdate(true);
                    window.setTimeout(() => {
                      navigate('/profiles');
                    }, 500);
                  }
                );
                
          window.webengage.track('profile_deleted', { 'event_source': 'Web' });
          mixpanel.track('profile_deleted', { 'event_source': 'Web' });
              }}
            />
            <BeginButton
              preset="link"
              type="button"
              className="link"
              to="/profiles"
              datalabel="Cancel"
            />
          </>
        )}
        {error !== '' && (
          <>
            <div className={avatarSelectionStyle.notFound}>
              <FaExclamationTriangle
                className={avatarSelectionStyle.errorIcon}
              />
              <br />
              {error}
            </div>
            <BeginButton
              preset="link"
              type="button"
              className="secondary"
              to="/profiles"
              datalabel="Back to Profiles"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DeleteProfile;
