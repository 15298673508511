export function getYear(date: string) {
  if (
    date !== null &&
    date !== undefined &&
    date !== '' &&
    date.includes('-')
  ) {
    return date.split('-')[0];
  } else {
    return '';
  }
}

export function getHoursAndMinutes(runtime: number) {
  if (runtime !== null && runtime !== undefined && runtime !== 0) {
    return `${Math.floor(runtime / 60)}h ${runtime % 60}m`;
  } else {
    return '';
  }
}

export function isSubscribed(subscriptions: any) {
  const isSubscribedVal = subscriptions?.find(
    (sub: any) => sub.isSubscribed === true
  );

  return typeof isSubscribedVal !== 'undefined';
}
