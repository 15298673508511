import React, { FC, useEffect, useState } from 'react';
import { landingActions } from '../../../store/landing-slice';
import { useDispatch } from 'react-redux/es/exports';
import style from '../auth-child.module.css';

import ProgressDots from '../../../components/progress-dots/progress-dots';
import { useNavigate } from 'react-router-dom';
import { addCustomerSubscriptions } from '../../../services/subscriptions';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';

export const Payment: FC = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [iframe, setIframe] = useState('');
  const [iframeRedirectCount, setIframeRedirectCount] = useState(0);

  useEffect(() => {
    window.setTimeout(() => {
      dispatch(landingActions.childLoaded());
    }, 1500);

    dispatch(landingActions.hideToast());
    dispatch(landingActions.showLoader());

    const subscriptionsListLocal = localStorage.getItem('subscriptionList');
    const selectedSubscriptionsLocal = localStorage.getItem(
      'selectedSubscriptions'
    );
    const costsLocal = localStorage.getItem('subscriptionCosts');

    if (subscriptionsListLocal && selectedSubscriptionsLocal && costsLocal) {
      // console.log('lastLocation', localStorage.getItem('lastLocation'));
      addCustomerSubscriptions((response) => {
        // window.location.href =
        //   response + '&redirect=' + window.location.origin + '/sports';
        ReactGA.event({
          category: 'Settings-Subscriptions',
          label: response,
          action: 'SS_PackageSelect_Success',
          nonInteraction: false,
        });
        ReactPixel.track('SS_PackageSelect_Success');
        window.location.href =
          response +
          '&redirect=' +
          window.location.origin +
          localStorage.getItem('lastLocation');
      });

      dispatch(landingActions.hideLoader());
    } else {
      navigate('/account/available-subscriptions');
    }
  }, []);

  const paymentSubmit = (event: any) => {
    event.preventDefault();
    setIframeRedirectCount(iframeRedirectCount + 1);

    if (iframeRedirectCount >= 5) {
      navigate('/tv-shows');
    }
  };

  return (
    <div className={style.authContainer}>
      <form onSubmit={paymentSubmit} className={style.form}>
        {/* <div className={style.authContent}>
          <h1 className="title">Payment</h1>

          <div className={style.group}>
            <ProgressDots
              dots={3}
              activeIndex={2}
            />
          </div>

          <div className={style.group}>
            {iframe != '' && (
              <iframe
                src={iframe}
                className={style.paymentIframe}
                onLoad={paymentSubmit}
              ></iframe>
            )}
          </div>
        </div> */}
      </form>
    </div>
  );
};
