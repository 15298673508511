import React, { useEffect } from 'react';
import styles from './ContentDetails2.module.css';
import TvShowDetails from './TvShowDetails/TvShowDetails';

import { FaTimes } from 'react-icons/fa';
import PlayerComponent from '../jwPlayer/jwPlayer';
import MovieDetails from './MovieDetails/MovieDetails';
import SportsDetails from './SportsContent/SportsContent';

import logo from '../../assets/images/logo.png';
import { Playlist } from '../../types/local-models/ContentDetails.model';

interface ContentCarouselModel {
  content: any;
  onClick: any;
}
interface ContentDetailsProps {
  onClose: () => void;
  contentID: string;
  contentType: string;
}

interface sportsSeries {
  seasonID: string;
  seasonName: string;
}
interface Image {
  imageType: string;
  imagePath: string;
}

const ContentDetails2: React.FC<ContentDetailsProps> = ({
  onClose,
  contentType,
  contentID,
}) => {
  const [playerURL, setPlayerURL] = React.useState('');
  const [playerTitle, setPlayerTitle] = React.useState('');
  const [playerMediaID, setPlayerMediaID] = React.useState('');
  const [drmToken, setDRMToken] = React.useState('');
  const [loadPlayer, setLoadPlayer] = React.useState(false);
  const [isMobile, setIsMobile] = React.useState(false);
  const [showNonPlayable, setShowNonPlayable] = React.useState(false);

  const [playlist, setPlaylist] = React.useState<Playlist>({
    playlistIndex: 0,
    PlaylistItems: [],
  });

  const module = () => {
    switch (contentType) {
      case 'movies':
        return (
          <MovieDetails
            contentID={contentID}
            setPlayerUrl={setPlayerURL}
            isMobile={isMobile}
            setPlaylist={setPlaylist}
            setShowNonPlayable={setShowNonPlayable}
          />
        );
      case 'tv-shows':
        return (
          <TvShowDetails
            contentID={contentID}
            setPlayerUrl={setPlayerURL}
            isMobile={isMobile}
            setPlaylist={setPlaylist}
            setShowNonPlayable={setShowNonPlayable}
          />
        );
      case 'sports':
        return (
          <SportsDetails
            contentID={contentID}
            setPlayerUrl={setPlayerURL}
            setPlayerTitle={setPlayerTitle}
            setPlayerMediaID={setPlayerMediaID}
            setDRMToken={setDRMToken}
            isMobile={isMobile}
            setPlaylist={setPlaylist}
            setShowNonPlayable={setShowNonPlayable}
          />
        );
      default:
        console.log('Invalid content type: ', contentType);
        onClose();
    }
  };

  useEffect(() => {
    if (playerURL === '') {
      setLoadPlayer(false);
    } else {
      window.setTimeout(() => {
        setLoadPlayer(true);
      }, 2500);
    }

    setIsMobile(window.innerWidth < 1000);

    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };

    window.addEventListener('resize', handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [playerURL]);

  return (
    <div
      className={styles.contentDetailsWrapper}
      onClick={onClose}
    >
      <div
        className={styles.contentDetailsContainer}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className={styles.contentDetailsHeader}>
          <div
            className={styles.closeButton}
            onClick={onClose}
          >
            <FaTimes />
          </div>
        </div>
        {module()}
      </div>

      <div
        className={`${styles.playerContainer} ${playerURL === '' || showNonPlayable ? '' : styles.active
          }`}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <img
          src={logo}
          alt=""
          className={styles.playerLogo}
        />
        {playerURL && contentType != 'sports' && (
          <PlayerComponent
            playerEnabled={true}
            vodContent={true}
            contentID={contentID}
            playlist={playlist.PlaylistItems}
            playlistIndex={playlist.playlistIndex}
          />
        )}

        {playerURL && contentType == 'sports' && loadPlayer === true && (
          <PlayerComponent
            playerEnabled={true}
            vodContent={true}
            contentID={contentID}
            // playlist={[
            //   {
            //     file: playerURL,
            //     mediaid: playerMediaID,
            //     title: playerTitle,
            // image:
            //   'https://s3.ap-southeast-1.amazonaws.com/begin.assets/avatars/avatar_01.png',
            // drm: {
            //   widevine: {
            //     url: 'https://widevine-license.vudrm.tech/proxy',
            //     headers: [
            //       {
            //         name: 'X-VUDRM-TOKEN',
            //         value: drmToken,
            //       },
            //     ],
            //   },
            //   playready: {
            //     // url: 'https://playready-license.vudrm.tech/rightsmanager.asmx',
            //     // headers: [
            //     //   {
            //     //     name: 'X-VUDRM-TOKEN',
            //     //     value:
            //     //       'jw_prd_jgaevsdp|2024-02-09T22:52:49Z|v2|uzBVNPieohImmBXO80QYInABo6/n8/lplmlANeaNC14=|436f4a6eea019c4a701c9a5207d70e289b761397c24f2aa8affd0abcae243888',
            //     //   },
            //     // ],
            //   },
            // },
            //   },
            // ]}
            playlist={playlist.PlaylistItems}
            playlistIndex={playlist.playlistIndex}
          />
        )}

        <div className={styles.playerCloseButton}>
          <FaTimes
            onClick={() => {
              setPlayerURL('');
            }}
          />
        </div>
      </div>

      {showNonPlayable && (<div
        className={`${styles.playerContainer} ${showNonPlayable ? styles.active : ''}`}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ textAlign: 'center' }}>
            <img
              src={logo}
              alt=""
              width={120}
            />
            <h1>Oops! We&apos;ve detected a VPN or proxy.</h1>
            <p>Please disable it for echanced experience.</p>
          </div></div>

        <div className={styles.playerCloseButton}>
          <FaTimes
            onClick={() => {
              setShowNonPlayable(false);
            }}
          />
        </div>
      </div>)}
    </div>
  );
};

export default ContentDetails2;
