import { configureStore } from '@reduxjs/toolkit';
import landingSlice from './landing-slice';

const store = configureStore({
  reducer: {
    landing: landingSlice.reducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
