import React, { useEffect } from 'react';
import { tvShowsApi } from '../../services/content-apis/tvShows';
import ContentList from '../../components/content-list';
import Loading from '../../components/Loading/Loading';

import style from './userContentStyle.module.css';
interface UserTVSeriesProps {
  genre: string;
}

const UserTvShows = ({ genre }: UserTVSeriesProps) => {
  const [data, setData] = React.useState<any[]>([]);
  const [responseHasFeatured, setResponseHasFeatured] =
    React.useState<boolean>(true);
    const [showLoader, setShowLoader] = React.useState(true);

  useEffect(() => {
    const getSeries = async () => {
      tvShowsApi.getAll(genre).then((response: any) => {
        setResponseHasFeatured(
          response.some((item: any) => item.carousel === 'Featured')
        );
        setData(response);
      });
    };

    getSeries();
  }, [genre]);

  return (
    <>
      {!responseHasFeatured && <div className={style.placeholder}></div>}
      {data &&
        data.length > 0 &&
        data[0]?.carousel ?
        data.map(({ priority, carousel, series }, index) => (
          <ContentList
            key={'tv-shows-' + carousel + '-' + index}
            priority={priority}
            carousel={carousel}
            list={series}
            type="tv-shows"
            showLoader={showLoader}
            setShowLoader={setShowLoader}
          />
        )) : <Loading show={showLoader} />}
    </>
  );
};

export default UserTvShows;
