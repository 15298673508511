import React, { FC, useEffect } from 'react';
import styles from './Landing.module.css';
import ScrollToTop from '../../helpers/ScrollToTop';

import { motion } from 'framer-motion';
import ReactGA from 'react-ga4';
const TermsAndConditions: FC = () => {
  const containerVariants = {
    offscreen: {
      y: -50,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.1,
        duration: 1.2,
        y: { duration: 1 },
      },
    },
  };

  return (
    <>
      <ScrollToTop />
      <motion.section
        className={`${styles.legalSection} ${styles.leftAlign}`}
        layout
        variants={containerVariants}
        initial="offscreen"
        whileInView="onscreen"
        animate={'show'}
      >
        <h1 className={styles.title}>TERMS &amp; CONDITIONS</h1>
        <p>
          BEGIN provides a personalized video on demand service that allows our
          members to access entertainment content (“BEGIN content”) over the
          Internet on certain Internet-connected TVs, computers, and other
          devices (“Begin ready devices”).
        </p>
        <p>
          These Terms of Use govern your use of our service. As used in these
          Terms of Use, “BEGIN Service”, “our service” or “the service” means
          the personalized service provided by BEGIN for discovering and
          accessing BEGIN content, including all features and functionalities,
          recommendations and reviews, our websites, and user interfaces, as
          well as all content and software associated with our service.
        </p>
        <p>
          By using our service, you confirm that you agree with and are bound by
          the terms of service contained in the Terms & Conditions outlined
          below. These terms apply to the entire website and any email or other
          type of communication between you and BEGIN.
        </p>

        <h2>BEGIN SERVICE:</h2>
        <ul>
          <li>
            You must be at least 13 years of age, or the age of majority in your
            province, territory, or country, to become a member of the BEGIN
            service. Minors may only use the service under the supervision of an
            adult.
          </li>
          <li>
            The BEGIN service and any content accessed through the service are
            for your personal and non-commercial use only and may not be shared
            with individuals beyond your household. During your BEGIN membership
            we grant you a limited, non-exclusive, non-transferable right to
            access the BEGIN service and BEGIN content. Except for the
            foregoing, no right, title, or interest shall be transferred to you.
            You agree not to use the service for public performances.
          </li>
          <li>
            You may access the BEGIN content primarily within the country in
            which you have established your account and only in geographic
            locations where we offer our service and have licensed such content.
            The content that may be available will vary by geographic location
            and will change from time to time.
          </li>
          <li>
            The BEGIN service, including the content library, is regularly
            updated. In addition, we continually test various aspects of our
            service, including our websites, user interfaces, promotional
            features, and availability of BEGIN content. 
          </li>
          <li>
            You agree to use the BEGIN service, including all features and
            functionalities associated therewith, in accordance with all
            applicable laws, rules and regulations, or other restrictions on use
            of the service or content therein. You agree not to archive,
            reproduce, distribute, modify, display, perform, publish, license,
            create derivative works from, offer for sale, or use (except as
            explicitly authorized in these Terms of Use) content and information
            contained on or obtained from or through the BEGIN service. You also
            agree not to: circumvent, remove, alter, deactivate, degrade or
            thwart any of the content protections in the BEGIN service; use any
            robot, spider, scraper or other automated means to access the BEGIN
            service; decompile, reverse engineer or disassemble any software or
            other products or processes accessible through the BEGIN service;
            insert any code or product or manipulate the content of the BEGIN
            service in any way; or use any data mining, data gathering or
            extraction method. In addition, you agree not to upload, post,
            e-mail or otherwise send or transmit any material designed to
            interrupt, destroy or limit the functionality of any computer
            software or hardware or telecommunications equipment associated with
            the BEGIN service, including any software viruses or any other
            computer code, files or programs. We may terminate or restrict your
            use of our service if you violate these Terms of Use or are engaged
            in illegal or fraudulent use of the service.
          </li>
          <li>
            The quality of the display of the BEGIN content may vary from device
            to device, and may be affected by a variety of factors, such as your
            location, the bandwidth available through and/or speed of your
            Internet connection. SD, HD and HDR availability is subject to your
            Internet service and device capabilities. Not all content is
            available in all formats, such as HD or HDR. Default playback
            settings on cellular networks exclude HD and HDR content. The
            minimum connection speed for SD quality is 1.0 Mbps; however, we
            recommend a faster connection for improved video quality. A download
            speed of at least 4.0 Mbps per stream is recommended to receive HD
            content (defined as a resolution of 720p or higher).  You are
            responsible for all Internet access charges. Please check with your
            Internet provider for information on possible Internet data usage
            charges. The time it takes to start watching BEGIN content will vary
            based on a number of factors, including your location, available
            bandwidth at the time, the content you have selected and the
            configuration of your BEGIN ready device.
          </li>
          <li>
            BEGIN software is developed by, or for, BEGIN and may solely be used
            for authorized streaming and to access BEGIN content through BEGIN
            ready devices. This software may vary by device and medium, and
            functionalities and features may also differ between devices. You
            acknowledge that the use of the service may require third party
            software that is subject to third party licenses. You agree that you
            may automatically receive updated versions of the BEGIN software and
            related third-party software.
          </li>
        </ul>

        <h2>REGISTRATION:</h2>
        <p>
          To register for the BEGIN service, you shall be required to open an
          account by completing the registration process by providing us with
          your current, complete, and accurate information as prompted by the
          applicable registration form; you shall also choose a password and a
          username. BEGIN reserves the right to suspend or terminate your
          registration as a registered user without assigning any reason
          (including for provision of inaccurate, not current, or incomplete
          information during the registration process or thereafter). As a
          registered user, you shall be responsible for safeguarding your
          password and for all transactions undertaken using your username and
          password. You agree not to disclose your password to any third party
          and to take sole responsibility for any activities or actions under
          your account, whether you have authorized such activities or actions.
          Specifically, as a parent or legal guardian, you acknowledge and
          assume sole responsibility to ensure that any content, which is meant
          for mature audiences, i.e., above the age of majority, is not accessed
          by children. Hence, you may not share your log in credentials with
          your children. It is your sole responsibility to change your password
          immediately if you believe that your password has been compromised.
          BEGIN will not be responsible for any financial loss, inconvenience or
          mental agony resulting from misuse of your username and password in
          any circumstances. You expressly agree to absolve BEGIN and/or the
          BEGIN Service of any responsibility/ liability in this regard. We
          reserve the right to refuse service to anyone for any reason at any
          time.
        </p>

        <h2>BILLING AND ACCOUNT INFORMATION:</h2>
        <p>
          By subscribing to BEGIN, you are expressly agreeing that BEGIN is
          authorized to charge you subscription fee as per your selected plan,
          any other fees for additional services you may purchase, and any
          applicable taxes in connection with your use of BEGIN’s Service
          through any such payment method/ instrument including but not limited
          to credit/debit card, net banking or other payment method/ instrument
          accepted by BEGIN (“Payment Method”). The Payment Methods will differ
          from platform to platform (operating systems). The length of the
          Billing cycle will depend on the type of subscription that you choose
          when you sign up for the BEGIN service. The subscription fee will be
          billed at the beginning of your subscription membership and on each
          subsequent renewal (as per the subscription plan chosen by you) unless
          and until you cancel your subscription, or the subscription is
          otherwise suspended or discontinued pursuant to these Terms. In the
          event you wish to obtain any assistance or have any queries in
          relation to cancelling your subscription, you may contact us at 
          support@begin.watch Depending on packages and/or value-added services
          and the terms of the proposed subscription, you will be required to
          make payment via a Payment Method provided by BEGIN for accessing and
          browsing BEGIN App. If you wish to avail BEGIN after the expiry of the
          initial term of subscribed packages, you shall prior to the expiry of
          such term, recharge your account with the then prevailing subscription
          charges. The Subscription or any information with respect to same is
          not transferable to any new or existing account under any
          circumstances. However, any changes in the Subscription Plan opted by
          You is effective only after the expiry of the current Subscription
          period for which You have already been billed. Accordingly, the
          Subscription as per revised plan opted by You shall be effective only
          after the expiry of the then current Subscription period. You agree to
          provide current, complete and accurate account information for all
          subscriptions. You agree to promptly update your account and other
          information, including your email address and payment information, so
          that we can complete your transactions and contact you as needed.
        </p>

        <h2>SUBSCRIPTION:</h2>
        <p>
          To be able to view certain premium content (“Premium Content”), you
          will be required to subscribe to any one subscription plan (“Plan”) on
          BEGIN Website/ Application. Any registered user can avail the
          subscription package (“Subscription User”). As a Subscription User,
          you will be required to subscribe to Subscription Packages made
          available by BEGIN from time to time. The subscription to such
          Packages may be subject to additional terms specified by BEGIN. BEGIN
          reserves the right to modify or discontinue Service at its sole
          discretion with or without notice to you. BEGIN shall not be liable to
          you or any third party in any manner, should BEGIN exercise its right
          to modify or discontinue their service. Further, BEGIN reserves the
          right, at any time, with or without notice and without any liability
          to change, terminate or otherwise amend the Subscription Plans,
          Subscription Fees for the Subscription, billing cycle and any part of
          BEGIN Services at its sole discretion and at any time. Such amendments
          shall be effective upon posting on the site/app and your continued use
          of the services shall be deemed to be your acceptance of such
          amendments Further, BEGIN reserves the right to change, supplement,
          alter or remove packaging and introduce base and add on packages
          and/or offer content on individual basis that is subject to
          subscription as it deems fit. BEGIN does not guarantee the
          availability of a specific content or minimum amount of content that
          is subject to subscription fees. Additionally, BEGIN shall have the
          right, but not the obligation, to refrain from providing to you any
          content in the interest of national security or in the event of
          emergency/ war or similar situation or if the Content is
          anti-national, promotes political/ religious propaganda, is against
          public policy, is banned or restricted from being distributed under
          any applicable law or BEGIN otherwise determine that it is
          objectionable or is derogatory to any person or class of persons,
          hurts the religious sentiments of any religious group or infringes the
          privacy rights of any individual(s) or is not in the interest of
          BEGIN’s subscribers or the general public.
        </p>

        <h2>PAYMENT GATEWAY:</h2>
        <p>
          To use the BEGIN service you must provide one or more Payment Methods.
          You authorize us to charge any Payment Method associated to your
          account in case your primary Payment Method is declined or no longer
          available to us for payment of your subscription fee. You remain
          responsible for any uncollected amounts. If a payment is not
          successfully settled, due to expiration, insufficient funds, or
          otherwise, and you do not cancel your account, we may suspend your
          access to the service until we have successfully charged a valid
          Payment Method. For some Payment Methods, the issuer may charge you
          certain fees, such as foreign transaction fees or other fees relating
          to the processing of your Payment Method. Local tax charges may vary
          depending on the Payment Method used. Check with your Payment Method
          service provider for details. You can update your Payment Methods by
          going to the “Account” page. We may also update your Payment Methods
          using information provided by the payment service providers. Following
          any update, you authorize us to continue to charge the applicable
          Payment Method(s). If a credit card and/or debit card and/or net
          banking and/or cash card ­­­transaction and/or any other mode
          available, you must use your own credit card and/or debit card and/or
          net banking account and/or cash card. You confirm and acknowledge that
          you are aware of the fact that when making any online payment through
          credit card or debit card or net banking account or cash card or via
          any other mode available, you may be directed to an external payment
          gateway page. The payment gateway may redirect you to other website(s)
          maintained or controlled by third parties, and BEGIN does not control
          such third party website(s) and hence is not responsible for any
          transactions on such website(s). BEGIN will not be liable for any
          credit card or debit card or net banking or cash card fraud and BEGIN
          will not entertain or address any such grievances or issues. You are
          requested to communicate all grievances related to such issues to your
          bank or mobile carrier/operator or mobile wallet provider who has
          issued such a credit card or debit card or net banking account or cash
          card. BEGIN shall not be responsible for all or any dispute or
          difference relating to online payment made by You through credit card
          or debit card or net banking account or cash card or via any other
          mode available. Further, BEGIN will not be responsible for any
          financial loss, inconvenience or mental agony resulting from misuse of
          your credit card or debit card or net banking account number or cash
          card and other details. Payments once made by credit card or debit
          card or net banking or cash cards or via any other mode available
          shall not be refunded in any circumstances. You are hereby advised to
          keep details of your credit/debit card and net banking confidential
          and do not share any such details with any other third party.
        </p>

        <h2>NO REFUNDS:</h2>
        <p>
          The Subscription Fees once billed is non-refundable irrespective of
          whether the subscription has been used by you or not and there are no
          refunds or credits for partially used periods. Following any
          cancellation, however, you will continue to have access to the service
          through the end of current billing period.
        </p>

        <h2>ACCOUNT ACCESS:</h2>
        <p>
          The member who created the BEGIN account and whose Payment Method is
          charged (the “Account Owner”) has access and control over the BEGIN
          account and the BEGIN ready devices that are used to access our
          service and is responsible for any activity that occurs through the
          BEGIN account. To maintain control over the account and to prevent
          anyone from accessing the account (which would include information on
          viewing history for the account), the Account Owner should maintain
          control over the BEGIN ready devices that are used to access the
          service and not reveal the password or details of the Payment Method
          associated with the account to anyone. You are responsible for
          updating and maintaining the accuracy of the information you provide
          to us relating to your account. If BEGIN reasonably believes that an
          account and password is being misused in any manner, BEGIN reserves
          the right to cancel access rights immediately without notice, and
          block access to all users from that IP address. You agree to notify
          BEGIN immediately of any unauthorized use of your account or any other
          breach of security. BEGIN will not be liable for any loss that you may
          incur because of someone else using your password or account, however,
          you could be held liable for losses incurred by BEGIN or another party
          due to someone else using your account or password.
        </p>

        <h2>RESTRICTIONS & PROHIBITED USES:</h2>
        <p>
          You agree not to, either directly or through the use of any device,
          software, web-based service, or by other means, copy, download,
          archive, perform, display, upload, publish, transmit or retransmit the
          Content or create any work or material that is derived from or based
          on the Content, rent, lease, duplicate, sub-license, assign, pledge,
          loan, or resell the Content of BEGIN. You shall only use BEGIN on
          Device(s) that is under your exclusive control and ownership. You
          shall not permit any third party to benefit from the use or
          functionality of BEGIN, either directly or via any facility
          management, time-sharing, service bureau or any other arrangement. If
          You transfer possession of any copy of BEGIN to another party, your
          subscription of BEGIN shall automatically get terminated and further
          actions shall be taken against you as prescribed in law and legal
          remedies available. You shall not use any “deep-link”, “page-scrape”,
          “robot”, “spider” or other automatic device(s), program, algorithm or
          methodology, or any similar or equivalent manual process, to access,
          acquire, copy or monitor any portion of BEGIN or any content, or in
          any way reproduce or circumvent the navigational structure or
          presentation of BEGIN or any content, to obtain or attempt to obtain
          any materials, documents or information through any means not
          purposely made available through BEGIN App. We reserve the right to
          bar any such activity. You shall not probe, scan or test the
          vulnerability of BEGIN website or any network connected to BEGIN nor
          breach the security or authentication measures on BEGIN or any network
          connected to BEGIN. You may not reverse look-up, trace or seek to
          trace any information on any other user of or visitor to BEGIN to its
          source. You also agree that you will not take any action that imposes
          an unreasonable or disproportionately large load on the infrastructure
          of BEGIN or any systems or networks connected to BEGIN. You may not
          pretend that you are, or that you represent, someone else, or
          impersonate any other individual or entity. Your correspondence or
          business dealings with, or participation in promotions of, third party
          advertisers found on or through BEGIN, including payment and delivery
          of goods or services, and any other terms, conditions, warranties or
          representations associated with such dealings, are solely between you
          and such third party advertiser. BEGIN shall not be responsible or
          liable for any loss or damage of any sort incurred as the result of
          any such dealings or as the result of the presence of such third party
          advertisers on You acknowledge and agree that BEGIN is not responsible
          or liable for: 1. the availability or accuracy of such websites or
          resources; or 2. the content, products, or services on or available
          from such websites or resources. Links to such websites or resources
          do not imply any endorsement by BEGIN of such websites or resources or
          the content, products, or services available from such websites or
          resources. You acknowledge sole responsibility for and assume all
          risks arising from Your use of any such websites or resources. It is
          possible that other users (including unauthorized users or “hackers”)
          may post or transmit offensive or obscene materials on BEGIN App and
          that You may be involuntarily exposed to such offensive and obscene
          materials. It also is possible for others to obtain personal
          information about You on the public forum due to Your use of the BEGIN
          App, and that the recipient may use such information to harass or
          injure You. BEGIN does not approve of such unauthorized uses but by
          browsing/using the BEGIN App, you acknowledge and agree that BEGIN
          shall not be responsible for the use of any personal information that
          you publicly disclose or share with others on BEGIN. In addition to
          other prohibitions as set forth in the Terms of Service, you are
          prohibited from using the site or its content: i. for any unlawful
          purpose; ii. to solicit others to perform or participate in any
          unlawful acts; iii. to violate any international, federal, provincial
          or state regulations, rules, laws, or local ordinances; iv. to
          infringe upon or violate BEGIN’s intellectual property rights or the
          intellectual property rights of others; v. to harass, abuse, insult,
          harm, defame, slander, disparage, intimidate, or discriminate based on
          gender, sexual orientation, religion, ethnicity, race, age, national
          origin, or disability; vi. to submit false or misleading information;
          vii. to upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Service or of any related website, other websites,
          or the Internet; viii. to collect or track the personal information of
          others; ix. to spam, phish, harm, pretext, spider, crawl, or scrape;
          (x)for any obscene or immoral purpose; or x. to interfere with or
          circumvent the security features of the Service or any related
          website, other websites, or the Internet. BEGIN reserves the right to
          terminate your use of the Service or any related Service for violating
          any of the prohibited uses.
        </p>

        <h2>WARRANTIES AND LIMITATIONS ON LIABILITY:</h2>
        <p>
          You expressly agree that your use of our Service is at your sole risk.
          The BEGIN service, website, application, and access to optional tools
          is provided “as is” and “as available” without warranty,
          representations or condition of any kind and without any endorsement.
          Its affiliates expressly disclaim all warranties of any kind, whether
          express or implied (including, without limitation, the implied
          warranties of merchantability, fitness for a particular use or purpose
          and non- infringement). Neither BEGIN nor any of its affiliates make
          any warranties or representations about the accuracy or completeness
          of content available on or through the Service or the content of any
          web sites, personal social media or other internet or mobile resources
          linked to the Service and assume no liability or responsibility for
          any errors, mistakes, or inaccuracies of content. In no case shall
          BEGIN, our directors, officers, employees, affiliates, agents,
          contractors, interns, suppliers, service providers or licensors be
          liable for any injury, loss, claim, or any direct, indirect,
          incidental, punitive, special, or consequential damages of any kind,
          including, without limitation lost profits, lost revenue, lost
          savings, loss of data, replacement costs, or any similar damages,
          whether based in contract, tort (including negligence), strict
          liability or otherwise, arising from your use of any of the service or
          any packages procured using the service, or for any other claim
          related in any way to your use of the service or any package,
          including, but not limited to, any errors or omissions in any content,
          or any loss or damage of any kind incurred as a result of the use of
          the service or any content posted, transmitted, or otherwise made
          available via the Service, even if advised of their possibility.
          Because some jurisdictions do not allow the exclusion or the
          limitation of liability for consequential or incidental damages, in
          such states or jurisdictions, our liability shall be limited to the
          maximum extent permitted by law. BEGIN does not guarantee, represent
          or warrant that your use of our service will be uninterrupted, timely,
          secure or error-free. BEGIN does not warrant that the results that may
          be obtained from the use of the Service will be accurate or reliable.
          You waive all special, indirect and consequential damages against us.
          These terms will not limit any non-waivable warranties or consumer
          protection rights that you may be entitled to under the laws of your
          country of residence. BEGIN shall have no liability whatsoever arising
          from or relating to your use of optional third-party tools. Any use by
          you of optional tools offered through the site is entirely at your own
          risk and discretion and you should ensure that you are familiar with
          and approve of the terms on which tools are provided by the relevant
          third- party provider(s)
        </p>

        <h2>THIRD-PARTY LINKS:</h2>
        <p>
          You acknowledge and agree that certain content, products and services
          available via our Service may include materials from third-parties.
          Third-party links on this site may direct you to third-party websites
          that are not affiliated with us. BEGIN is not responsible for
          examining or evaluating the content or accuracy and BEGIN does not
          warrant and will not have any liability or responsibility for any
          third-party materials or websites, or for any other materials,
          products, or services of third-parties. BEGIN is not liable for any
          harm or damages related to the subscriptions or use of services,
          resources, content, or any other transactions made in connection with
          any third-party websites. Please review carefully the third- party’s
          policies and practices and make sure you understand them before you
          engage in any transaction. Complaints, claims, concerns, or questions
          regarding third-party products should be directed to the third-party.
        </p>

        <h2>USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS:</h2>
        <p>
          If, at our request, you send certain specific submissions (for example
          contest entries) or without a request from us you send creative ideas,
          suggestions, proposals, plans or other materials, whether online, by
          email, by postal mail, or otherwise (collectively, ‘comments’), you
          agree that BEGIN may, at any time, without restriction, edit, copy,
          publish, distribute, translate and otherwise use in any medium any
          comments that you forward to us. BEGIN is not  and shall be under no
          obligation i) to maintain any comments in confidence; ii) to pay
          compensation for any comments; or iii) to respond to any comments.
          BEGIN may, but has no obligation to, monitor, edit or remove content
          that BEGIN determines in our sole discretion is unlawful, offensive,
          threatening, libelous, defamatory, pornographic, obscene or otherwise
          objectionable or violates any party’s intellectual property or these
          Terms of Service. You will not interfere with or disrupt, or attempt
          to interfere with or disrupt, the operation of the Service or any
          parts thereof. You will not remove, alter, interfere with or
          circumvent any digital rights management mechanism, device or other
          content protection or access control measure including, without
          limitation Geo-filtering and/or encryption, etc., associated with
          Service content. You agree that your comments will not violate any
          rights of any third-party, including copyright, trademark, privacy,
          personality or other personal or proprietary right. You further agree
          that your comments will not contain defamatory, otherwise unlawful,
          abusive or obscene material, or contain any computer virus or other
          malware that could in any way affect the operation of the Service or
          any related website. You may not use a false e-mail address, pretend
          to be someone other than yourself, or otherwise mislead us or third
          parties as to the origin of any comments. You are solely responsible
          for any comments you make and their accuracy. BEGIN takes no
          responsibility and assume no liability for any comments posted by you
          or any third-party.
        </p>

        <h2>CHANGES TO TERMS OF SERVICE:</h2>
        <p>
          You can review the most current version of the Terms of Service at any
          time at this page. BEGIN App reserves the right, at its sole
          discretion, to update, change or replace any part of these Terms of
          Service by posting updates and changes to our website. Your continued
          use of or access to our website or the Service following the posting
          of any changes to these Terms of Service constitutes acceptance of
          those changes.
        </p>

        <h2>PRIVACY POLICY:</h2>
        <p>
          Your submission of personal information through the subscription is
          governed by our Privacy Policy.
        </p>

        <h2>RELATIONSHIP:</h2>
        <p>
          The relationship between BEGIN App and You is on a
          principal-to-principal basis. You are in no way BEGIN’s legal
          representative, partner, or agent for any reason whatsoever.
        </p>

        <h2>NOTICES:</h2>
        <p>
          Any notices or other communications required will be in writing and
          emailed to BEGIN at support@begin.watch for notices made by e-mail,
          the date of receipt will be deemed the date on which such notice is
          transmitted. In the event you have any complaints with respect to any
          Content on the BEGIN app, please write to us at support@begin.watch
          with details of the objectionable content and Your details including
          Your name, mobile number and such other details as may be requested by
          Us. Based on the complaints raised, we will take reasonable measures
          to resolve the issue. You hereby expressly agree that the BEGIN app
          shall under no circumstance be liable to You for any damages
          whatsoever. Further, you hereby expressly agree that the BEGIN app
          makes no representations under these Terms of Use that any complaint
          raised by you will be resolved to your satisfaction. All steps to be
          taken by the BEGIN app in this regard shall be at the sole discretion
          of BEGIN.
        </p>

        <h2>TERMINATION:</h2>
        <p>
          Your Membership will continue until terminated. You may terminate
          these Terms of Service at any time by notifying us that you no longer
          wish to use our Services, or when you cease using our site/ App. As a
          Subscription User, your right to use the BEGIN app shall automatically
          terminate on the expiry of the term of the Subscription Package unless
          you renew it. Your right to use the BEGIN app shall automatically
          terminate if you violate these Terms of Use or any terms, rules or
          guidelines published in connection with the BEGIN app. BEGIN reserves
          the right, in its sole discretion, to suspend or terminate Your access
          to all or any part of the BEGIN app, for any reason, with or without
          notice. You agree that upon suspension or termination, no amount shall
          be refunded to You by the BEGIN app.
        </p>

        <h2>COPYRIGHT INFRINGEMENT NOTIFICATION:</h2>
        <p>
          BEGIN app respects the intellectual property rights of others. Upon
          proper notice, the BEGIN app will remove user submissions (and any
          other Service content) that violate copyright law. BEGIN shall remove
          upon receiving written notification of claimed copyright infringement
          on the Service and for processing such claims in accordance with law.
          If you believe a work has been copied in a way that constitutes
          copyright infringement, please send a notification of claimed
          infringement (an “Infringement Notification”) that contains all the
          following information: (i) identification of the copyrighted work
          claimed to have been infringed; (ii) identification of the claimed
          infringing material and information reasonably sufficient to permit
          the BEGIN app to locate the material on Website/ Application; (iii)
          information reasonably sufficient to permit the BEGIN app to contact
          You, such as an address, telephone number, and, if available, an
          e-mail address; (iv) a statement by you that you have a good faith
          belief that the disputed use is not authorized by the copyright owner,
          its agent, or the law; (v) a statement by you, made under penalty of
          perjury, that the above information in your notification is accurate
          and that you are the copyright owner or authorized to act on the
          copyright owner’s behalf; and (vi) your physical signature. By
          submitting an infringement notification, you acknowledge and agree
          that the BEGIN app may forward your infringement notification and any
          related communications to any users who posted the material identified
          in such notice.
        </p>
        <p>
          The BEGIN app is not able to mediate rights ownership disputes. When
          BEGIN receives a complete and valid takedown notice, BEGIN shall
          remove the content as the law requires. When the BEGIN app receives a
          valid counter notification, it shall forward it to the person who
          requested the removal. After this, it is up to the parties involved to
          resolve the issue in court.
        </p>

        <h2>ERRORS, INACCURACIES AND OMISSIONS:</h2>
        <p>
          Occasionally there may be information on our app or in the Service
          that contains typographical errors, inaccuracies or omissions that may
          relate to product descriptions, pricing, promotions, offers,
          availability etc. We reserve the right to correct any errors,
          inaccuracies or omissions, and to change or update information or
          cancel subscription(s) if any information in the Service or on any
          related website is inaccurate at any time without prior notice,
          including after you have subscribed the packages. We undertake no
          obligation to update, amend or clarify information in the Service or
          on any related website, including without limitation, pricing
          information, except as required by law.
        </p>

        <h2>INDEMNIFICATION:</h2>
        <p>
          You agree to indemnify, defend, and hold harmless, the BEGIN app, its
          parent, subsidiaries, affiliates, partners, officers, directors,
          consultants, agents, licensors, contractors, sub-contractors, service
          providers, suppliers, interns and employees (“indemnified parties”)
          from and against any and all claims, liabilities, damages, losses,
          costs, expenses, fees (including reasonable attorneys’ fees and costs)
          that such indemnified parties may incur as a result of or arising from
          i) any information (including, without limitation, your user generated
          content, feedback, or any other content) you (or anyone using your
          account) submit, post, or transmit on or through the BEGIN app; ii)
          Your (or anyone using your account’s) use of the BEGIN app; iii) Your
          (or anyone using your account’s) violation of these terms; or iv) Your
          (or Anyone using your account’s) violation of any rights of any other
          person or entity, including, without limitation, any copyright,
          patent, trademark, trade secret or other proprietary rights of any
          person or entity. The BEGIN app reserves the right, at its own
          expense, to assume the exclusive defense and control of any matter
          otherwise subject to indemnification by you, in which event you will
          cooperate with the BEGIN app in asserting any available defenses. This
          provision shall remain in full force and effect notwithstanding any
          termination of your use of the site.
        </p>

        <h2>SEVERABILITY:</h2>
        <p>
          In the event that any provision of these Terms of Service is
          determined to be unlawful, void or unenforceable, such provision shall
          nonetheless be enforceable to the fullest extent permitted by
          applicable law, and unenforceable portion shall be deemed to be
          severed from these Terms of Service, such determination shall not
          affect validity and enforceability of any other remaining provisions.
        </p>

        <h2>GOVERNING LAW:</h2>
        <p>
          These Terms of Service and any separate agreements whereby we provide
          you Services shall be governed by and construed in accordance with the
          laws of Pakistan.
        </p>

        <h2>ENTIRE AGREEMENT:</h2>
        <p>
          The failure of us to exercise or enforce any right or provision of
          these Terms of Service shall not constitute a waiver of such right or
          provision. These Terms of Service and any policies or operating rules
          posted by us on this site or in respect to the service constitutes the
          entire agreement and understanding between You and the BEGIN app and
          govern Your use of the Service, superseding any prior or
          contemporaneous agreements, communications and proposals, whether oral
          or written, between You and the BEGIN app (including, but not limited
          to, any prior versions of the Terms of Service). Any ambiguities in
          the interpretation of these Terms of Service shall not be construed
          against the drafting party. The headings used in this agreement are
          included for convenience only and will not limit or otherwise affect
          these Terms.
        </p>

        <h2>CUSTOMER SUPPORT:</h2>
        <p>
          To find more information about our service and is features or if you
          need assistance with your account, write it to us on
          support@begin.watch
        </p>
      </motion.section>
    </>
  );
};

export default TermsAndConditions;
