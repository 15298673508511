import api, { Headers } from './config/api';
import { v4 as uuidv4 } from 'uuid';

export const DevicesApi = {
  // TODO: Need to fix for acountID && persist in browser storage after add into device
  addDevice: async (token: string) => {
    const url = 'accounts/devices';
    const payload = {
      operation: 'create',
      payload: {
        accountID: 'engr.mzs@gmail.com', // Cogntio Username
        deviceUDID: uuidv4(),
        attributes: {
          devicePlatform: 'web',
          deviceFCMToken: token,
        },
      },
    };

    try {
      const res = await api.post(url, payload);
      console.log(res);
      return res;
    } catch (error) {
      console.log(error);
    }
  },
};
