import React, { FC, useEffect, useState } from 'react';
import styles from './Landing.module.css';
import BeginButton from '../begin-button/begin-button';
import BeginInput from '../form-input-fields/begin-input';
import ReactGA from 'react-ga4';

import mobileImg from '../../assets/images/home-page/mobile-home-screen.webp';
import browserImg from '../../assets/images/home-page/browser-home-page.webp';
import kidsContentImg from '../../assets/images/home-page/kids-content.webp';
import ScrollToTop from '../../helpers/ScrollToTop';

import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import FAQ from '../FAQ/FAQ';
import ReactPixel from 'react-facebook-pixel';
import mixpanel from 'mixpanel-browser';

mixpanel.init('9913d3bf0504bcc0c3ac83182867f483', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
});
const LandingSections: FC = () => {
  const [email, setEmail] = useState('');

  const phoneOrBrowserTextControl = useAnimation();
  const [phoneOrBrowserTextRef, inViewPhoneOrBrowserText] = useInView();

  const phoneOrBrowserImgsControl = useAnimation();
  const [phoneOrBrowserImgsRef, inViewphoneOrBrowserImgs] = useInView();

  const uninterruptedStreamingControl = useAnimation();
  const [uninterruptedStreamingRef, inViewUninterruptedStreaming] = useInView();

  const kidsImgControl = useAnimation();
  const [kidsImgRef, inViewKidsImg] = useInView();

  const kidsTextControl = useAnimation();
  const [kidsTextRef, inViewKidsText] = useInView();

  const faqTControl = useAnimation();
  const [faqTRef, inViewFaqT] = useInView();

  const faqSControl = useAnimation();
  const [faqSRef, inViewFaqS] = useInView();

  useEffect(() => {
    ReactGA.event({
      category: 'Landing Page',
      action: 'LandingPage_View',
      label: 'send_page_view',
      nonInteraction: false,
    });

    ReactPixel.track('LandingPage_View');
    window.webengage.track('entry_screen_viewed', {'event_source' : 'Web', })
    mixpanel.track('entry_screen_viewed', {'event_source': 'Web'})
    if (inViewPhoneOrBrowserText) {
      phoneOrBrowserTextControl.start('onscreen');
    } else {
      phoneOrBrowserTextControl.start('offscreen');
    }

    if (inViewphoneOrBrowserImgs) {
      phoneOrBrowserImgsControl.start('onscreen');
    } else {
      phoneOrBrowserImgsControl.start('offscreen');
    }

    if (inViewUninterruptedStreaming) {
      uninterruptedStreamingControl.start('onscreen');
    } else {
      uninterruptedStreamingControl.start('offscreen');
    }

    if (inViewKidsImg) {
      kidsImgControl.start('onscreen');
    } else {
      kidsImgControl.start('offscreen');
    }

    if (inViewKidsText) {
      kidsTextControl.start('onscreen');
    } else {
      kidsTextControl.start('offscreen');
    }

    if (inViewFaqT) {
      faqTControl.start('onscreen');
    } else {
      faqTControl.start('offscreen');
    }

    if (inViewFaqS) {
      faqSControl.start('onscreen');
    } else {
      faqSControl.start('offscreen');
    }
  }, [
    phoneOrBrowserImgsControl,
    inViewPhoneOrBrowserText,
    inViewphoneOrBrowserImgs,
    inViewUninterruptedStreaming,
    inViewKidsImg,
    inViewKidsText,
    inViewFaqT,
    inViewFaqS,
  ]);

  const containerVariantsTop = {
    offscreen: {
      y: -100,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.1,
        duration: 0.5,
        y: { duration: 1 },
      },
    },
  };

  const containerVariantsBottom = {
    offscreen: {
      y: 100,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      y: 0,
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.1,
        duration: 0.5,
        y: { duration: 1 },
      },
    },
  };

  const containerVariantsLeft = {
    offscreen: {
      x: -50,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.1,
        duration: 0.5,
        x: { duration: 0.5 },
      },
    },
  };

  const containerVariantsRight = {
    offscreen: {
      x: 50,
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      x: 0,
      transition: {
        delayChildren: 0.25,
        staggerChildren: 0.1,
        duration: 0.5,
        x: { duration: 0.5 },
      },
    },
  };

  const emailOnChange = (value: string) => {
    setEmail(value);
  };

  return (
    <>
      <ScrollToTop />
      <motion.section
        className={`${styles.landingSection} ${styles.firstSection}`}
        variants={containerVariantsBottom}
        initial="offscreen"
        whileInView="onscreen"
        animate={'show'}
      >
        <div className={styles.landingScreen}>
          <div className={styles.title}>Experience unlimited entertainment</div>

          <div className={styles.subtitle}>
            Uninterrupted streaming. Anywhere, anytime.
          </div>

          <div className={styles.content}>
            Ready to watch? Enter your email address to create or reset your
            membership
          </div>

          {/* <a href="beginwatch://?success=true">Open App</a> */}

          <div className={`${styles.content} ${styles.emailForm}`}>
            <div className={styles.registerBtnContainer}>
              <BeginButton
                className="primary"
                datalabel="Get Started"
                to="/account/signup"
                type="button"
                preset="link"
                squared={true}
                onClick={() => {
                window.webengage.track('signup_clicked', {'event_source' : 'Web', })
                mixpanel.track('signup_clicked', {'event_source': 'Web'})
                }}
              />
            </div>
          </div>
          <div className={styles.content}>
            Subscribe now and get 7 days trial for FREE
          </div>
        </div>
      </motion.section>

      <motion.section
        className={`${styles.landingSection} ${styles.wideSection}`}
      >
        <div className={`${styles.landingScreen} ${styles.phoneOrBrowser}`}>
          <motion.div
            ref={phoneOrBrowserTextRef}
            variants={containerVariantsLeft}
            initial="offscreen"
            animate={phoneOrBrowserTextControl}
          >
            <div className={`${styles.title} ${styles.leftAlign}`}>
              Enjoy on your Phone,
              <br />
              Browser Or Android TV!
            </div>

            <div className={`${styles.content} ${styles.leftAlign}`}>
              Coming soon to Smart TVs with Tizen, Xbox, 
              <br />
              Blu-ray players, Chromecast and many more.
            </div>
          </motion.div>

          <motion.div
            className={styles.phoneOrBrowserImages}
            ref={phoneOrBrowserImgsRef}
            variants={containerVariantsRight}
            initial="offscreen"
            animate={phoneOrBrowserImgsControl}
          >
            <div className={styles.mobile}>
              <img src={mobileImg} />
            </div>
            <div className={styles.browser}>
              <img src={browserImg} />
            </div>
          </motion.div>
        </div>
      </motion.section>

      <motion.section
        className={styles.landingSection}
        ref={uninterruptedStreamingRef}
        variants={containerVariantsTop}
        initial="offscreen"
        animate={uninterruptedStreamingControl}
      >
        <div className={styles.landingScreen}>
          <div className={styles.title}>Uninterrupted Streaming</div>

          <div className={styles.content}>
            Watch unparalleled content on your Smartphones
            <br />
            or in your browser, anywhere.
          </div>
        </div>
      </motion.section>

      <motion.section className={styles.landingSection}>
        <div className={`${styles.landingScreen} ${styles.phoneOrBrowser}`}>
          <motion.div
            className={styles.phoneOrBrowserImages}
            ref={kidsImgRef}
            variants={containerVariantsLeft}
            initial="offscreen"
            animate={kidsImgControl}
          >
            <div className={styles.kids}>
              <img src={kidsContentImg} />
            </div>
          </motion.div>

          <motion.div
            ref={kidsTextRef}
            variants={containerVariantsRight}
            initial="offscreen"
            animate={kidsTextControl}
          >
            <div className={`${styles.title} ${styles.leftAlign}`}>
              Unlimited content for Kids
            </div>

            <div className={`${styles.content} ${styles.leftAlign}`}>
              Unleash a world of boundless adventure for your children as they
              watch their beloved characters in their favourite movies.
            </div>
          </motion.div>
        </div>
      </motion.section>

      <section
        className={`${styles.landingSection} ${styles.landingFaqSection}`}
      >
        <div className={`${styles.landingScreen} ${styles.faqContent}`}>
          <motion.div
            className={styles.title}
            ref={faqTRef}
            variants={containerVariantsTop}
            initial="offscreen"
            animate={faqTControl}
          >
            Frequently Asked Questions
          </motion.div>

          <motion.div
            className={`${styles.content} ${styles.leftAlign}`}
            ref={faqSRef}
            variants={containerVariantsBottom}
            initial="offscreen"
            animate={faqSControl}
          >
            <FAQ />
          </motion.div>
        </div>
      </section>
    </>
  );
};

export default LandingSections;
