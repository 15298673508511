import api, {Headers} from '../config/api';
import {getPayLoad, getCachedPayload} from '../config/payload';
import cachedApi from '../config/cachedapi';

import {isSubscribed} from './helpers';
import mixpanel from 'mixpanel-browser';
mixpanel.init('9913d3bf0504bcc0c3ac83182867f483', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  });
  
function convertToCardData(res: any) {
  if(!res[0]) {
        console.log('SDFsdfsdfsdfsdfsdfsdfsdf')
    console.log(res);
  }
  const cardData = {
    genres       : res[0].genres.map((genre: any) => genre.genreName).join(' / '),
    runtime      : res[0].seriesRuntime + ' minutes / match',
    publishedYear:
      res[0].seasons.length +
      (res[0].seasons.length > 1 ? ' Seasons' : ' Season'),
    tagline      : res[0].seriesOverview,
    isSubscribed : isSubscribed(res[0].subscriptions),
    streamLink   : res,
  };
  return cardData;
}

const url         = 'content/sports';
const episodesUrl = 'content/episodes';

export const sportsApi = {
  getAll: async (genre: string) => {
     window.webengage.track('category_viewed', { 
      'category_name': 'Sports', 
      'event_source': 'Web'
    });
    mixpanel.track('category_viewed', { 
      'category_name': 'Sports', 
      'event_source': 'Web'
    });
    const payload = getPayLoad({
      screen    : 'view all',
      seriesType: 'sports',
      genre     : genre,
      profileID : localStorage.getItem('profileID') || '',
    });

    try {
      const res = await api.post(url, payload, {
        headers: Headers,
      });
      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  //   getAll: async (genre: string) => {
  //   const payload = getCachedPayload({
  //     operation: 'read',
  //     term: 'sports'
  //   });

  //   try {
  //     const res = await cachedApi.post('getCarousels', payload, { headers: Headers });
  //     //console.log('sports response', res.data.data);
  //     return res.data.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },


  getTVShow: async (id: string, type: string) => {
    const payload = {
      operation: 'read',
      payload: {
        seriesType: 'sports',
        seriesID: id,
        accountID: localStorage.getItem('accountID'),
        profileID: localStorage.getItem('profileID') || '',
        select: [],
        filter: {},
      },
    };

    try {
      const res = await api.post(url, payload, {
        headers: Headers,
      });
      return convertToCardData(res.data.data);
    } catch (error) {
      console.log(error);
    }
  },

  getEpisodesBySeason: async (seasonID: string) => {
    const payload = {
      operation: 'list',
      payload  : {
        seasonID : seasonID,
        accountID: localStorage.getItem('accountID'),
        grouped  : true,

      },
    };

    try {
      const res = await api.post(episodesUrl, payload, {
        headers: Headers,
      });

    // const res = await cachedApi.post('getMovieDetails', payload, { headers: Headers });
    // console.log(res.data)
      return res.data.data; 
    } catch (error) {
      console.log(error);
    }
  },

  getEpisode: async (seasonID: string, episodeID: string) => {
    const payload = {
      operation: 'read',
      payload  : {
        seasonID : seasonID,
        episodeID: episodeID,
      },
    };

    try {
      const res = await api.post(episodesUrl, payload, {
        headers: Headers,
      });

      return res.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  
  // getEpisode: async (seasonID: string, episodeID: string) => {
  //   const payload = {
  //     operation: 'read',
  //     payload  : {
  //       id : seasonID,
  //       accountID: localStorage.getItem('accountID'),
  //       grouped:false
  //       // term: 'sports'
  //       // episodeID: episodeID,
  //     },
  //   };

  //   try {
     
  //     const res = await cachedApi.post('getEpisodes', payload, { headers: Headers });
  //     return res.data.data;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
  

  getIsLiveAndSubscribed: async () => {
    return true;
  },
};
