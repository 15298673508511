import React, { FC, useEffect, useState } from 'react';
import styles from './UserHome.module.css';
import logo from '../../assets/images/logo.png';
import { isSubscribed } from '../../services/subscriptions';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  ViewsProps,
  ScreenComponents,
} from '../../types/local-models/begin-views';
// components
import UserTvShows from '../UserContent/UserTvShows';
import {
  GenresApi,
  MovieGenres,
  TVseriesGenres,
} from '../../services/content-apis/genres';
// import PlayerComponent from '../../components/jwPlayer/jwPlayer';
import ContentSubscriptions from '../../components/content-subscriptions/ContentSubscriptions';
import ContentDetails2 from '../../components/content-details-2/ContentDetails2';
import UserMovies from '../UserContent/UserMovies';
import UserSports from '../UserSports/UserSports';
import { FaTimes } from 'react-icons/fa';
import PlayerComponent from '../../components/jwPlayer/jwPlayer';
import { drmAPI } from '../../services/content-apis/DRM';

import Loading from '../../components/Loading/Loading';
import mixpanel from 'mixpanel-browser';
mixpanel.init('9913d3bf0504bcc0c3ac83182867f483', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  });
  
const UserHome: FC<ViewsProps> = (props) => {
  const menuEntries = [
    {
      id: 1,
      label: 'Movies',
      path: '/movies',
    },
    {
      id: 2,
      label: 'TV Shows',
      path: '/tv-shows',
    },
    {
      id: 3,
      label: 'Sports',
      path: '/sports',
    },
    // {
    //   id: 4,
    //   label: 'Explore',
    //   path: '/explore',
    // },
  ];
  const route = useNavigate();
  const [contentSelected, setContentSelected] = useState<string>('');
  const [genres, setGenres] = React.useState<any[]>([]);
  const [movieGenres, setMovieGenres] = React.useState<any[]>([]);
  const [tvSeriesGenres, setSeriesGenres] = React.useState<any[]>([]);
  const [selectedGenre, setSelectedGenre] = React.useState('');
  const [isScrolled, setIsScrolled] = useState(false);


  const navigate = useNavigate();

  const [profile, setProfile] = useState<any>({});

  const [playerURL, setPlayerURL] = React.useState('');
  const [playerTitle, setPlayerTitle] = React.useState('');
  const [playerMediaID, setPlayerMediaID] = React.useState('');

  const [drmToken, setDRMToken] = React.useState('');
  const [loadPlayer, setLoadPlayer] = React.useState(false);

  const handleClose = () => {
    setContentSelected('');
    window.setTimeout(() => {
      switch (props.contentType) {
        case 'movies':
          navigate('/movies');
          break;
        case 'tv-shows':
          navigate('/tv-shows');
          break;
        case 'sports':
          navigate('/sports');
          break;
        // case 'explore':
        //   navigate('/explore');
        //   break;
        default:
          navigate('/tv-shows');
      }
    }, 500);
  };

  const params = useParams();

  useEffect(() => {
    isSubscribed(() => {
      const getGenres = async () => {
        GenresApi.getAll().then((genresResponse) => {
          setGenres(genresResponse);
        });
      };

      const getMovieGenres = async () => {
        MovieGenres.getAll().then((genresResponse) => {
          setMovieGenres(genresResponse);
        });
      };
      const getTvSeriesGenres = async () => {
        TVseriesGenres.getAll().then((genresResponse) => {
          setSeriesGenres(genresResponse);
        });
      };
      getGenres();
      getMovieGenres();
      getTvSeriesGenres();
      const selectedProfile = JSON.parse(
        localStorage.getItem('profile') || '{}'
      );
      setProfile(selectedProfile);
    });

    if (typeof params.contentID != 'undefined') {
      setContentSelected(params.contentID);
      document.body.style.overflow = 'hidden';
    } else {
      setContentSelected('');
      document.body.style.overflowY = 'auto';
    }

    window.addEventListener('scroll', () => {
      setIsScrolled(window.scrollY > 20);
    });
  }, [params, props]);

  const getFilteredContent = () => {
    return [];
  };

  const getProfileMenu = () => {
    return (
      <div className={styles.profileMenu}>
        <div>
          <Link
            to="/profiles"
            onClick={() => {
              localStorage.setItem('lastLocation', window.location.pathname);
            }}
          >
            Profiles
          </Link>
        </div>
        <div>
          <Link
            to="/settings/account"
            onClick={() => {
              localStorage.setItem('lastLocation', window.location.pathname);
            }}
          >
            Settings
          </Link>
        </div>
        <div>
          <Link to="/account/logout">Logout</Link>
        </div>
      </div>
    );
  };

  const onSearch = () => {
    route('/user-search');
  };

  const getSection = () => {
    switch (props.component) {
      case ScreenComponents.movies:
        return <UserMovies genre={selectedGenre} />;
      case ScreenComponents['tv-shows']:
        return <UserTvShows genre={selectedGenre} />;
      case ScreenComponents['sports']:
        return (
          <UserSports
            genre={selectedGenre}
            setPlayerUrl={setPlayerURL}
            setPlayerTitle={setPlayerTitle}
            setPlayerMediaID={setPlayerMediaID}
          />
        );
      default:
        return '';
    }
  };

  const subscriptionsForContent = (subscriptions: any) => {
    return <ContentSubscriptions {...subscriptions} />;
  };

  useEffect(() => {
    if (playerURL === '') {
      setLoadPlayer(false);
    } else {
      window.setTimeout(() => {
        setLoadPlayer(true);
      }, 2500);
    }
  }, [playerURL]);

  return (
    
    <div className={styles.contentView}>
      <Loading show={false}/>
      <header
        className={`${styles.head} ${isScrolled ? styles.scrolledHead : ''}`}
      >
        <div
          className={`${styles.mainHead} ${
            isScrolled ? styles.scrolledMainHead : ''
          }`}
        >
          <div className={styles.firstCol}>
            <div className={styles.logo}>
              <img
                className={styles.logo}
                src={logo}
              />
            </div>
            <div
              className={styles.menu}
              id="user-menu"
            >
              {menuEntries.map((entry) => {
                return (
                  <NavLink
                    to={entry.path}
                    key={entry.id}
                    className={styles.menuEntry}
                    onClick={() => {
                      setSelectedGenre('');
                      getFilteredContent();
                    }}
                  >
                    {entry.label}
                  </NavLink>
                );
              })}
            </div>
          </div>

          <div className={styles.secondCol}>
            <div
              onClick={onSearch}
              className={styles.menuEntry}
            >
              <i className="icon-search"></i>
            </div>
            <div className={styles.menuEntry}>
              <i className="icon-bell-alt"></i>
            </div>
            <div className={`${styles.menuEntry} ${styles.profileBtn}`}>
              <img
                src={profile?.acctProfileAvatar}
                alt=""
                className={styles.profileImg}
              />
              {getProfileMenu()}
            </div>
          </div>
        </div>

        <div
          className={`${styles.subHead} ${
            isScrolled ? styles.scrolledSubHead : ''
          }`}
        >
          <h1 className={styles.contentTypeTitle}>
            {menuEntries[props.component].label}
          </h1>
          <div>
            {movieGenres &&
              movieGenres?.length > 0 &&
              props.contentType === 'movies' && (
                <div>
                  <select
                    name="movieGenres"
                    id="movieGenres"
                    className={styles.genres}
                    onChange={(event) => {
                      const selectedOption = event.target.options[event.target.selectedIndex];   
                      const selectedText = selectedOption.innerHTML;    
                       window.webengage.track('genre_selected', {
                            'genres_name' : selectedText, 
                            'category_name': 'Movies', 
                            'event_source' :'Web',
                        })
                        mixpanel.track('genre_selected', {
                            'genres_name' : selectedText, 
                            'category_name': 'Movies', 
                            'event_source' :'Web',
                        })
                      setSelectedGenre(event.target.value);
                    }}
                  >
                    <option value="">All</option>
                    {movieGenres?.map((genre) => (
                      <option
                        key={genre.genreID}
                        value={genre.genreID}
                      >
                        {genre.genreName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
          </div>
          <div>
            {tvSeriesGenres &&
              tvSeriesGenres.length > 0 &&
              props.contentType === 'tv-shows' && (
                <div>
                  <select
                    name="tvSeriesGenres"
                    id="tvSeriesGenres"
                    className={styles.genres}
                    onChange={(event) => {
                      const selectedOption = event.target.options[event.target.selectedIndex];   
                      const selectedText = selectedOption.innerHTML;    
                       window.webengage.track('genre_selected', {
                            'genres_name' : selectedText, 
                            'category_name': 'Tv Shows', 
                            'event_source' :'Web',
                        })
                        mixpanel.track('genre_selected', {
                            'genres_name' : selectedText, 
                            'category_name': 'Tv Shows', 
                            'event_source' :'Web',
                        })
                      setSelectedGenre(event.target.value);
                    }}
                  >
                    <option value="">All</option>
                    {tvSeriesGenres.map((genre) => (
                      <option
                        key={genre.genreID}
                        value={genre.genreID}
                      >
                        {genre.genreName}
                      </option>
                    ))}
                  </select>
                </div>
              )}
          </div>
        </div>
      </header>

      {/* <PlayerComponent
        playerEnabled={true}
        playlist={[
          {
            file: 'https://a462e4c71949ab8af13120eaecfdc1f8.egress.mediapackage-vod.ap-southeast-1.amazonaws.com/out/v1/c057774e233c4ae288130c580ca34e9c/2327aa0dfcb04c678cd9bbc59f11e82d/b8fb9b89a1834cb7996bec3745037d8c/index.mpd',
            // image:
            //   'https://s3.ap-southeast-1.amazonaws.com/begin.assets/avatars/avatar_01.png',
            drm: {
              widevine: {
                url: 'https://widevine-license.vudrm.tech/proxy',
                headers: [
                  {
                    name: 'X-VUDRM-TOKEN',
                    value:
                      'jw_prd_jgaevsdp|2024-03-02T09:05:32Z|v2|qdXMUuVxrO/5+hdrY2V5jXL27VHeUvFb3aZqO/rAnZg=|605bf7df01f1feb2d0418007139f0eae173414ad24d051fa9f145c801234f5a1',
                  },
                ],
              },
              playready: {
                // url: 'https://playready-license.vudrm.tech/rightsmanager.asmx',
                // headers: [
                //   {
                //     name: 'X-VUDRM-TOKEN',
                //     value:
                //       'jw_prd_jgaevsdp|2024-02-09T22:52:49Z|v2|uzBVNPieohImmBXO80QYInABo6/n8/lplmlANeaNC14=|436f4a6eea019c4a701c9a5207d70e289b761397c24f2aa8affd0abcae243888',
                //   },
                // ],
              },
            },
          },
        ]}
      /> */}

      {/* 
        // Children
      */}
      <div className={styles.content}>{getSection()}</div>

      {contentSelected != '' && (
        <div
          className={
            styles.contentScreen +
            ' ' +
            (Object.keys(contentSelected).length > 0 ? styles.openDetails : '')
          }
        >
          <ContentDetails2
            onClose={handleClose}
            contentType={props.contentType || ''}
            contentID={contentSelected}
          />
        </div>
      )}

      <div
        className={`${styles.playerContainer} ${
          playerURL === '' ? '' : styles.active
        }`}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <img
          src={logo}
          alt=""
          className={styles.playerLogo}
        />
        {playerURL && props.contentType != 'sports' && (
          <PlayerComponent
            playerEnabled={true}
            vodContent={true}
            playlist={[
              {
                file: playerURL,
              },
            ]}
          />
        )}

        {playerURL && props.contentType == 'sports' && loadPlayer === true && (
          <PlayerComponent
            playerEnabled={true}
            vodContent={true}
            playlist={[
              {
                file: playerURL,
                title: playerTitle,
                mediaid: playerMediaID,
              },
            ]}
          />
        )}

        <div className={styles.playerCloseButton}>
          <FaTimes
            onClick={() => {
              setPlayerURL('');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserHome;
