import api, {Headers} from '../config/api';

const url = 'drm/token';

export const drmAPI = {
  get: async (contentID: string) => {
    const payload = {
      operation: 'read',
      payload  : {
        contentID: contentID,
      },
    };

    try {
      const res = await api.post(url, payload, {
        headers: Headers,
      });
      console.log('drm response', res.data.data.token);
      return res.data.data.token;
    } catch (error) {
      console.log(error);
    }
  },
};
