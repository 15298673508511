import React, { Fragment, useEffect } from 'react';

import styles from './Settings.module.css';
import { NavLink, useNavigate } from 'react-router-dom';
import AccountDetails from './Modules/account-details';
import SubscriptionsSettings from './Modules/subscriptions';
import DevicesSettings from './Modules/devices';

import { FaTimes, FaBars } from 'react-icons/fa';
import PhoneNumber from './Modules/accountSettingsModules/phone-number';
import Password from './Modules/accountSettingsModules/password';
import Help from '../UserSettings/partials/help';

interface SettingsProps {
  module: string;
}

const Settings: React.FC<SettingsProps> = ({ module }) => {
  const modules = [
    {
      label: 'My Account',
      path: '/settings/account',
    },
    {
      label: 'Subscriptions',
      path: '/settings/subscriptions',
    },
    {
      label: 'Devices',
      path: '/settings/devices',
    },
    {
      label: 'Help',
      path: '/settings/help',
    },
  ];

  const [menuActive, setMenuActive] = React.useState(false);

  const navigate = useNavigate();

  const getModule = () => {
    switch (module) {
      case 'account':
        return <AccountDetails />;
      case 'phone':
        return <PhoneNumber />;
      case 'password':
        return <Password />;
      case 'subscriptions':
        return <SubscriptionsSettings />;
      case 'devices':
        return <DevicesSettings />;
      case 'help':
        return <Help />;
      default:
        break;
    }
  };

  return (
    <div className={styles.settingsWrapper}>
      <div className={styles.settingsContainer}>
        <div
          className={styles.hamburger}
          onClick={() => setMenuActive(!menuActive)}
        >
          <FaBars />
        </div>
        <div
          className={`${styles.settingsMenu} ${
            menuActive ? styles.active : ''
          }`}
        >
          <div className={styles.menuStyicky}>
            <div
              className={`${styles.closeIcon} ${styles.mobileMenu}`}
              onClick={() => setMenuActive(false)}
            >
              <FaTimes />
            </div>
            {modules.map((item, index) => (
              <Fragment key={`fragment-${index}`}>
                <div
                  key={index}
                  className={styles.menuItem}
                >
                  <NavLink
                    to={item.path}
                    className={styles.navContainer}
                    onClick={() => setMenuActive(false)}
                  >
                    {({ isActive, isPending, isTransitioning }) => (
                      <div
                        className={`${styles.nav} ${
                          isActive ? styles.active : ''
                        }`}
                      >
                        {item.label}
                      </div>
                    )}
                  </NavLink>
                </div>
              </Fragment>
            ))}
          </div>
        </div>

        <div
          className={styles.closeIcon}
          onClick={() => {
            navigate(localStorage.getItem('lastLocation') || '/');
          }}
        >
          <FaTimes />
        </div>

        <div className={styles.settingsContent}>{getModule()}</div>
      </div>
    </div>
  );
};

export default Settings;
