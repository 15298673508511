import React, { useState } from 'react';
import style from '../partials/partials.module.css';
import caution from '../../../assets/images/icons/Caution.png';

interface ModalProps {
  title: string;
  content: React.ReactNode;
  actions: { text: string; onClick: (text?: string) => void }[];
  onClose: (text?: string) => void;
  showTextArea?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  title,
  content,
  actions,
  onClose,
  showTextArea = false,
}) => {
  const [text, setText] = useState<string>('');

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  return (
    <div className={style.modalOverlay}>
      <div className={style.modalContent}>
        <div>
          <img
            src={caution}
            alt=""
          />
        </div>
        <h2>{title}</h2>
        <div className={style.modalBody}>
          {content}
          {showTextArea && (
            <textarea
              className={style.textArea}
              value={text}
              onChange={handleTextChange}
              placeholder="Enter your text here (maximum 250 characters)"
              maxLength={250}
            />
          )}
          <hr />
        </div>
        <div className={style.modalActions}>
          {actions.map((action, index) => (
            <button
              key={index}
              onClick={() => action.onClick(text)}
              className={
                index === 0 ? style.primaryButton : style.secondaryButton
              }
            >
              {action.text}
            </button>
          ))}
        </div>
        {/* <button onClick={() => onClose(text)} className={style.closeButton}>
          Close
        </button> */}
      </div>
    </div>
  );
};

export default Modal;
