import React from 'react';
import { Link } from 'react-router-dom';
import styles from './begin-button.module.css';
import {
  BeginButtonContainerModel,
  BeginButtonModel,
} from '../../types/local-models/begin-button.component.model';
import Loading from '../Loading/Loading';

function BeginButton(props: BeginButtonModel, squared: boolean) {
  function showLoading() {
    return props.loading ? <div className={styles.loading}></div> : '';
  }

  return (
    <>
      {(props.preset == 'button' || typeof props.preset === 'undefined') &&
        typeof props.onClick == 'undefined' && (
          <button
            type={typeof props.type !== 'undefined' ? props.type : 'button'}
            disabled={props.disabled}
            className={`${styles.btn} ${styles[props.className]} ${
              squared ? styles.squared : ''
            } ${props.iconOnly ? styles.iconOnly : ''} ${
              props.tall ? styles.tall : ''
            } ${props.bold ? styles.bold : ''}`}
          >
            {props.dataiconstart && <i className={props.dataiconstart}></i>}
            {props.datalabel}
            {props.dataiconend && <i className={props.dataiconend}></i>}

            {showLoading()}
          </button>
        )}

      {(props.preset == 'button' || typeof props.preset === 'undefined') &&
        typeof props.onClick !== 'undefined' && (
          <button
            type={typeof props.type !== 'undefined' ? props.type : 'button'}
            disabled={props.disabled}
            onClick={props.onClick}
            className={`${styles.btn} ${styles[props.className]} ${
              squared ? styles.squared : ''
            } ${props.tall ? styles.tall : ''} ${
              props.iconOnly ? styles.iconOnly : ''
            } ${props.bold ? styles.bold : ''}`}
          >
            {props.dataiconstart && <i className={props.dataiconstart}></i>}
            {props.datalabel}
            {props.dataiconend && <i className={props.dataiconend}></i>}

            {showLoading()}
          </button>
        )}

      {props.preset == 'link' &&
        typeof props.onClick == 'undefined' &&
        typeof props.to !== 'undefined' && (
          <Link
            to={props.to}
            className={`${styles.btn} ${styles[props.className]} ${
              squared ? styles.squared : ''
            } ${props.tall ? styles.tall : ''} ${
              props.iconOnly ? styles.iconOnly : ''
            } ${props.bold ? styles.bold : ''}`}
            target={typeof props.target != 'undefined' ? props.target : '_self'}
            rel={typeof props.rel != 'undefined' ? props.rel : ''}
          >
            {props.dataiconstart && <i className={props.dataiconstart}></i>}
            {props.datalabel}
            {props.dataiconend && <i className={props.dataiconend}></i>}
          </Link>
        )}

      {props.preset == 'link' &&
        typeof props.onClick !== 'undefined' &&
        typeof props.to !== 'undefined' && (
          <Link
            to={props.to}
            className={`${styles.btn} ${styles[props.className]} ${
              squared ? styles.squared : ''
            } ${props.tall ? styles.tall : ''} ${
              props.iconOnly ? styles.iconOnly : ''
            } ${props.bold ? styles.bold : ''}`}
            onClick={props.onClick}
            target={typeof props.target != 'undefined' ? props.target : '_self'}
            rel={typeof props.rel != 'undefined' ? props.rel : ''}
          >
            {props.dataiconstart && <i className={props.dataiconstart}></i>}
            {props.datalabel}
            {props.dataiconend && <i className={props.dataiconend}></i>}
          </Link>
        )}
    </>
  );
}

export default BeginButton;
