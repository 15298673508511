import React, { useEffect, useState } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';

import { RootState } from '../../store';
import { useSelector } from 'react-redux/es/hooks/useSelector';

import styles from './auth.module.css';
import logo from '../../assets/images/logo.png';
import Loading from '../../components/Loading/Loading';
import BeginToast from '../../components/begin-toast/begin-toast';
import { AuthModel } from '../../types/local-models/auth.view.model';

import PlayerComponent from '../../components/jwPlayer/jwPlayer';

import {
  clearInterval,
  clearTimeout,
  setInterval,
  setTimeout,
} from 'worker-timers';

import {
  CheckForLiveEvent,
  RemainingTime,
} from '../../services/live-event-signup/checkForLiveEvents';

import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { LiveEventModel } from '../../types/local-models/live-event';

const Auth = (props: AuthModel) => {
  const [searchParams, setSearchParams] = useSearchParams();
  // Redirect URL parameters
  const c = searchParams.get('c');
  const pid = searchParams.get('pid');
  const shortlink = searchParams.get('shortlink');
  const deepLinkSub1 = searchParams.get('deep_link_sub1');

  const childLoaded = useSelector(
    (state: RootState) => state.landing.isChildLoaded
  );

  const showLoader = useSelector(
    (state: RootState) => state.landing.showLoader
  );
  const showToast = useSelector((state: RootState) => state.landing.toast);

  const [liveEvent, setLiveEvent] = useState<LiveEventModel | undefined>();
  const [authModuleShow, setAuthModuleShow] = useState(false);
  const [remainingTimeState, setRemainingTime] = useState(600);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setAuthModuleShow(false);

    window.blur = () => {
      console.log('blur');
    };

    const checkForLiveEvent = async () => {
      await CheckForLiveEvent((response, success) => {
        if (success) {
          RemainingTime((remainingTime, successResponseForRemainingTime) => {
            if (successResponseForRemainingTime) {
              setLiveEvent(response);

              setRemainingTime(remainingTime.playbackRemainingTime);
              if (remainingTime.playbackRemainingTime < 540) {
                setAuthModuleShow(true);
              }
              let timer = remainingTime.playbackRemainingTime;
              setInterval(() => {
                if (timer > 0) {
                  timer = timer - 1;
                  setRemainingTime(timer);

                  if (timer >= 10) {
                    setShowVideo(true);
                  }

                  if (
                    600 - timer >= 60 ||
                    remainingTime.playbackRemainingTime <= 540
                  ) {
                    setAuthModuleShow(true);
                  }
                } else {
                  setRemainingTime(0);
                }
              }, 1000);
            }
          });
        } else {
          setAuthModuleShow(true);
        }
      }, c);
    };

    if (typeof props.type !== 'undefined' && props.type === 'live') {
      setAuthModuleShow(false);
      checkForLiveEvent();
    } else {
      setAuthModuleShow(true);
    }
  }, []);

  return (
    <>
      {typeof props.type === 'undefined' && (
        <div className={styles.authBG}>
          {typeof liveEvent === 'undefined' && (
            <div
              className={`${styles.logoContainer} ${
                childLoaded ? styles.logoContainerChildLoaded : ''
              }`}
            >
              <img
                className={styles.logo}
                src={logo}
              />
            </div>
          )}
        </div>
      )}

      {typeof props.type !== 'undefined' &&
        props.type === 'live' &&
        typeof liveEvent !== 'undefined' && (
          <div
            className={`${styles.authLiveBG} ${
              authModuleShow ? styles.authModuleShown : ''
            }`}
          >
            <div className={styles.liveEventContainer}>
              {remainingTimeState > 0 && showVideo && (
                <PlayerComponent
                  playlist={[
                    {
                      file: liveEvent?.contentStreamLink,
                      image:
                        liveEvent?.subscriptions[0].subscriptionThumbnails[0],
                    },
                  ]}
                  playerEnabled={showVideo}
                />
              )}

              <div
                className={`${styles.liveEventDetailsContainer} ${
                  authModuleShow ? styles.authShown : ''
                } ${
                  remainingTimeState > 0 && showVideo ? styles.showVideo : ''
                }`}
              >
                {liveEvent && (
                  <>
                    <img
                      className={styles.liveEventLogo}
                      src={
                        liveEvent?.subscriptions[0].subscriptionThumbnails[0]
                      }
                    />

                    <div className={styles.liveEventTitle}>
                      {liveEvent.contentTitle}
                    </div>
                  </>
                )}

                {liveEvent && (
                  <>
                    <div className={styles.liveEventSubTitle}>Live Event</div>
                    <div className={styles.liveEventDate}>
                      {liveEvent.contentTitle}
                      &nbsp;vs&nbsp;
                      {liveEvent.contentOverview}
                    </div>
                  </>
                )}
              </div>

              <div
                className={`${styles.liveEventTime} ${
                  childLoaded && authModuleShow ? styles.childLoaded : ''
                }`}
              >
                <div style={{ marginBottom: '20px' }}>
                  Time Till Free Stream Ends
                </div>
                <CircularProgressbar
                  value={remainingTimeState}
                  maxValue={600}
                  text={`${
                    Math.floor(remainingTimeState / 60) +
                    ':' +
                    (remainingTimeState % 60 >= 10
                      ? remainingTimeState % 60
                      : '0' + (remainingTimeState % 60))
                  }`}
                />
              </div>
            </div>
          </div>
        )}

      <div
        className={`${styles.outlet} ${
          childLoaded && authModuleShow ? styles.outletChildLoaded : ''
        }`}
      >
        <Outlet />
        <Loading show={showLoader} />

        {showToast.text != '' && (
          <BeginToast
            type={showToast.type}
            shown={showToast.shown}
            text={showToast.text}
            dismissduration={showToast.dismissduration}
          />
        )}
      </div>
    </>
  );
};

export default Auth;
