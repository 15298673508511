import clsx from 'clsx';
import React, { useState } from 'react';
import SideBar from './side-bar';
import style from './partials.module.css';

import { FaTimes } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';

interface SettingsDialogProps {
  children: React.ReactNode;
}

const SettingsDialog = ({ children }: SettingsDialogProps) => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  return (
    <>
      <div
        className={clsx(style.backdrop, show && style.visible)}
        onClick={() => setShow(false)}
      ></div>
      {show && (
        <div className={clsx(style.content)}>
          <FaTimes
            className={clsx(style.close)}
            onClick={() =>
              navigate(localStorage.getItem('lastLocation') || '/')
            }
          />
          <SideBar onClick={() => setShow(false)} />
          <div className={clsx(style.children)}>{children}</div>
        </div>
      )}
    </>
  );
};

export default SettingsDialog;
