import React, { useEffect, useState } from 'react';
import BeginInput from '../../../components/form-input-fields/begin-input';

import styles from '../SettingsModules.module.css';
import {
  getCustomerDetailsAPI,
  updateCustomerStatus,
} from '../../../services/customer-details';
import BeginButton from '../../../components/begin-button/begin-button';
import { FaPencilAlt } from 'react-icons/fa';
import warning from '../../../assets/images/warning.png';
import { useNavigate } from 'react-router-dom';
import { deleteUser, logout } from '../../../services/Cognito';
import ReactGA from 'react-ga4';
import BeginToast from '../../../components/begin-toast/begin-toast';
import ReactPixel from 'react-facebook-pixel';
import mixpanel from 'mixpanel-browser';
mixpanel.init('9913d3bf0504bcc0c3ac83182867f483', {
  debug: true,
  track_pageview: true,
  persistence: 'localStorage',
  });
interface CustomerDetails {
  email: string;
  phone: string;
}

const AccountDetails: React.FC = () => {
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>({
    email: '',
    phone: '',
  });

  const navigate = useNavigate();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const maskCharacters = (unmaskedString: string) => {
    if (unmaskedString && unmaskedString.length > 5) {
      const firstFiveChars = unmaskedString.slice(0, 5);
      const lastTwoChars = unmaskedString.slice(-2);
      const maskedString = unmaskedString.slice(5, -2).replace(/\d/g, '*');
      return firstFiveChars + maskedString + lastTwoChars;
    } else {
      return unmaskedString;
    }
  };
  const logGAEditMobInit = () => {
    ReactGA.event({
      category: 'Account',
      action: 'SA_EditMobile_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('SA_EditMobile_Initiate');
  };
  const logGAEditPassInit = () => {
    ReactGA.event({
      category: 'Account',
      action: 'SA_EditPassword_Initiate',
      nonInteraction: false,
    });
    ReactPixel.track('SA_EditPassword_Initiate');
  };
  useEffect(() => {
    ReactGA.event({
      category: 'Account',
      action: 'SA_View',
      nonInteraction: false,
    });
    ReactPixel.track('SA_View');
  });
  useEffect(() => {
    // getCustomerDetailsAPI(
    getCustomerDetailsAPI(
      localStorage.getItem('accountID') || '',
      (response) => {
        console.log('response', response);
        setCustomerDetails({
          email: localStorage.getItem('accountID') || '',
          phone: response.accountPhone,
        });
      }
    );
  }, []);

  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmation(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteConfirmation(false);
  };

  const handleConfirmDelete = () => {
    console.log('id', localStorage.getItem('accountID'));
    deleteUser((err, result) => {
      if (err) {
        setError(err.message);

        setTimeout(() => {
          setError('');
        }, 5000);
      } else {
        updateCustomerStatus((response) => {
          if (response !== false) {
            setError('');
          } else {
            setError('Error deleting user');

            setTimeout(() => {
              setError('');
            }, 5000);
          }
        });

        setError('');
        setSuccess('Account deleted successfully');
        window.webengage.track('account_deleted', { 'event_source': 'Web' });
        mixpanel.track('account_deleted', { 'event_source': 'Web' });
        logout();
        localStorage.clear();
        setTimeout(() => {
          navigate('/account');
        }, 4000);
      }
    });
  };

  return (
    <div className={styles.childModule}>
      <div className={styles.input}>
        Email
        <BeginInput
          type="text"
          placeholder="Email"
          readonly={true}
          value={customerDetails.email}
          id="email"
          name="email"
        />
      </div>

      <div className={styles.input}>
        Mobile
        <div className={styles.editableInputContainer}>
          <div className={styles.editableInput}>
            <BeginInput
              type="text"
              placeholder="Mobile"
              readonly={true}
              value={maskCharacters(customerDetails.phone)}
              id="mobile"
              name="mobile"
            />
          </div>
          <div
            className={styles.editIcon}
            onClick={() => {
              navigate('/settings/account/edit-phone-number');
              logGAEditMobInit();
            }}
          >
            <FaPencilAlt />
          </div>
        </div>
      </div>

      <div className={styles.input}>
        Password
        <div className={styles.editableInputContainer}>
          <div className={styles.editableInput}>
            <BeginInput
              type="text"
              placeholder="Password"
              readonly={true}
              value="********"
              id="password"
              name="password"
            />
          </div>
          <div
            className={styles.editIcon}
            onClick={() => {
              navigate('/settings/account/edit-password');

              logGAEditPassInit();
            }}
          >
            <FaPencilAlt />
          </div>
        </div>
      </div>
      <div className={styles.buttonDiv}>
        <BeginButton
          className="cancel"
          datalabel="Delete"
          type="button"
          bold={true}
          dataiconstart="delete-info"
          squared={true}
          tall={true}
          onClick={handleDeleteConfirmation}
        />
      </div>
      {showDeleteConfirmation && (
        <div className={styles.popup}>
          <div>
            <div className={styles.iconContainer}>
              <img
                src={warning}
                alt="Icon"
                className={styles.icon}
              />
            </div>
            <p className={styles.popupHeading}>Are you sure?</p>
            <p className={styles.popupContent}>
              Deleting an account is a permanent action and can not be undone
            </p>
            <div className={styles.popupLine}></div>
            <div className={styles.popupButtons}>
              <BeginButton
                className="primary"
                datalabel="Cancel"
                type="button"
                onClick={handleCancelDelete}
              />
              <BeginButton
                className="cancel"
                datalabel="Confirm"
                type="button"
                onClick={handleConfirmDelete}
              />
            </div>
          </div>
        </div>
      )}

      <div
        onClick={() => {
          setError('');
        }}
      >
        <BeginToast
          text={error}
          type="error"
          shown={error !== '' && error !== null ? true : false}
          dismissduration={5000}
        />
      </div>

      <div
        onClick={() => {
          setSuccess('');
        }}
      >
        <BeginToast
          text={success}
          type="success"
          shown={success !== '' && success !== null ? true : false}
          dismissduration={5000}
        />
      </div>
    </div>
  );
};

export default AccountDetails;
