import api, {Headers} from '../config/api';

export const wishlistApi = {
  addToWishList: async (
    contentID: string,
    contentType: string,
    callback: (err: any, result: any) => void
  ) => {
    let wishlistType = contentType;

    switch (contentType) {
      case 'movies':
        wishlistType = 'movie';
        break;
      case 'tv-shows':
        wishlistType = 'tvseries';
        break;
      case 'sports':
        wishlistType = 'sports';
        break;
      default:
        console.log('Invalid content type');
    }

    if (localStorage.getItem('profileID')) {
      const payload = {
        operation: 'create',
        payload  : {
          profileID : localStorage.getItem('profileID'),
          contentID,
          attributes: {
            wishlistType: wishlistType,
          },
        },
      };

      try {
        const res = await api.post('accounts/wishlist', payload, {
          headers: Headers,
        });
        callback(null, res.data.data);
      } catch (error) {
        callback(error, null);
      }
    }
  },

  removeFromWishList: async (
    contentID: string,
    callback: (err: any, result: any) => void
  ) => {
    if (localStorage.getItem('profileID')) {
      const payload = {
        operation: 'delete',
        payload  : {
          profileID: localStorage.getItem('profileID'),
          contentID,
        },
      };

      try {
        const res = await api.post('accounts/wishlist', payload, {
          headers: Headers,
        });
        callback(null, res.data.data);
      } catch (error) {
        callback(error, null);
      }
    }
  },
};
