import { appEndpoints } from '../../api-endpoints';
import axios from 'axios';

import { Headers } from '../config/api';

import { getCurrentBrowserFingerPrint } from '@rajesh896/broprint.js';

export const CheckForLiveEvent = async (
  callback: (response: any, success: boolean) => void,
  liveUrl: string | null
) => {
  const url = appEndpoints.prod + 'content';

  const data = {
    operation: 'read',
    payload: {
      accountID: localStorage.getItem('accountID'), // TODO: Need to change with actual ID
      contentURL: liveUrl,
    },
  };

  try {
    await axios.post(url, data, { headers: Headers }).then((response): void => {
      callback(response.data?.data[0], true);
    });
  } catch (e) {
    callback(e, false);
    console.log('e', JSON.stringify(e));
  }
};

export const RemainingTime = async (
  callback: (response: any, success: boolean) => void
) => {
  await getCurrentBrowserFingerPrint().then(async (fingerprint) => {
    const url = appEndpoints.prod + 'content/playback';

    const data = {
      operation: 'read',
      payload: {
        deviceUDID: `${fingerprint}`,
        contentID: 'PSL-Live',
      },
    };
    try {
      const res = await axios.post(url, data, { headers: Headers });
      // TODO: Need to make better the following parsing
      callback(res.data?.data[0], true);
    } catch (e) {
      callback(e, false);
      console.log('e', JSON.stringify(e));
    }
  });
};
